import React, { useState } from "react";
import { VscThreeBars } from "react-icons/vsc";
import { NavMenuActiveClasses, NavMenuClasses } from "../../common/styles";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { NavItems } from "../../data/header";
import { Box, Drawer } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import WarningModal from "../../common/WarningModel";

export default function NavButtons({
  openWarning,
  setOpenWarning,
  handleLogin,
}) {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const isLogin = JSON?.parse(localStorage.getItem("client_info"));
  const navigate = useNavigate();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleCloseWarning = () => {
    setOpenWarning(false);
    localStorage.removeItem("OpenWarning");
  };

  const handleNavClick = (button) => {
    if (button.id === 7 && !isLogin) {
      handleLogin();
    } else if (button.id === 7) {
      window.scrollTo(0, 0);
      navigate("/dealers-portal");
    }
    setOpen(false); // Close the drawer after handling the navigation
  };

  return (
    <div className="sm:hidden">
      <div className="hover:cursor-pointer max-w-screen-xl flex flex-wrap items-center justify-between mx-auto ">
        <div className="hover:cursor-pointer px-5" onClick={toggleDrawer(true)}>
          <VscThreeBars size={24} color="black" />
        </div>
      </div>

      <div>
        <Drawer open={open} anchor="right" onClose={toggleDrawer(false)}>
          <Box className="flex-col py-5">
            {!!open && (
              <>
                {/* {isLogin?.given_name ? <div
                  className={`${NavMenuClasses} my-0`}>
                  Hello, {isLogin?.given_name} {isLogin?.family_name}
                </div>
                 : null} */}
                {NavItems.map((button) => (
                  <div key={button.id} className="my-7">
                    <NavLink
                      to={
                        button.id === 7
                          ? ""
                          : button.id === 6
                          ? "/contact"
                          : "/"
                      }
                      onClick={
                        button.id === 7
                          ? (e) => {
                              e.preventDefault();
                              handleNavClick(button);
                            }
                          : () => setOpen(false)
                      }
                      className={({ isActive }) =>
                        location?.pathname === button?.path
                          ? NavMenuActiveClasses
                          : NavMenuClasses
                      }
                    >
                      {button.text}
                    </NavLink>

                    {button?.subMenus?.map((subMenus) => (
                      <div key={subMenus.id} className="my-2">
                        <NavLink
                          to={subMenus.path}
                          style={{
                            width: "-webkit-fill-available",
                            paddingLeft: "20px",
                          }}
                          className={({ isActive }) =>
                            button.id === 1
                              ? NavMenuActiveClasses
                              : NavMenuClasses
                          }
                          onClick={() => setOpen(false)}
                        >
                          {subMenus.text}
                        </NavLink>
                      </div>
                    ))}
                  </div>
                ))}
              </>
            )}
          </Box>
        </Drawer>
      </div>

      <WarningModal open={openWarning} handleClose={handleCloseWarning} />
    </div>
  );
}
