export const ApplicationId = "30203E77-726D-4788-9788-B52F6CEEA99A";
export const PRODURL = "https://api.vizybility.net/";
export const TestURL = "https://dealerwebservice-api-dev.azurewebsites.net/";

export const vizybilityConfig = {
  STS_SIGNIN_URL: "https://sts.vizybility.net/connect/token",
  STS_CLIENT_ID: "viz-auth-serv-client",
  STS_CLIENT_SECRET:
    "mmAm#GKxXeyz7rtP8htzU8r5y8kdg0YloqlSfH9#Vo9uSbq7zFZaIvvP4EE@Dh",
  STS_SCOPE: "viz-crm-web-api-resource",
  grant_type: "password",
};
// ? Machine List

export const URL = PRODURL;

export const POST_CONTACT_US = `${URL}DealerWebApp/SendContactEmail`;
