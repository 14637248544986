import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomTable from "../../common/Table";
import "./vehicle-parts-lookup-info.css";
import LookupImg from "../../assets/png/Lookup.png";
import DealerPortalDrawer from "../../components/Layout/DealerPortalDrawer";
import { useEffect, useState } from "react";
import { getModelsSystemSummary } from "../../services/getModelsSystemSummary";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import OrderItemModel from "../../common/OrderItemModel";

const VehiclePartsLookupInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [equipmentSystemList, setEquipmentSystemList] = useState([]);
  const [systemPartsList, setSystemPartsList] = useState([]);
  const [partSystemImage, setPartSystemImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [activeDrillId, setActiveDrillId] = useState();
  const [activeDrillList, setActiveDrillList] = useState([]);

  const [outSideClick, setOutSideClick] = useState(false);
  // outSide Data
  const [outSideDataShow, setOutSideDataShow] = useState(false);
  const [selectOutSideData, setSelectOutSideData] = useState(null);
  const [outSideData, setOutSideData] = useState(false);
  const [outSideDataLoading, setOutSideDataLoading] = useState(false);

  const [refresh, setRefresh] = useState(true);

  function filterEquipmentSystems(data, targetId) {
    const result = [];
    let targetItem = data?.find((item) => item.EquipmentSystemId === targetId);
    if (targetItem) {
      while (targetItem) {
        result.unshift(targetItem);
        targetItem = data?.find(
          (item) => item.EquipmentSystemId === targetItem.ParentSystemId
        );
      }
    } else {
      return data;
    }

    return result;
  }

  const handleOutSideData = async (row) => {
    setOutSideDataShow(true);
    setSelectOutSideData(row);
    setOutSideDataLoading(true);
    try {
      const header = {
        authorization: `Bearer ${localStorage.getItem("vizy-bility-token")}`,
      };
      const url = `Parts/DrillDownEquipmentSystemById?equipmentSystemId=${row?.EquipmentSystemId}`;
      const data = await getModelsSystemSummary(url, null, header);

      setOutSideData(data?.data || []);
    } catch (error) {
      toast.error(error?.data);
    } finally {
      setOutSideDataLoading(false);
    }
  };

  const columns = [
    {
      label: "System Number",
      accessor: "SystemNumber",
      textAlignColumn: "center",
      // render: (_, rowIndex) => <div>{data[rowIndex]?.model || "-"}</div>,
    },
    {
      label: "System Code",
      accessor: "systemCode",
      textAlignColumn: "start",
    },
    {
      label: "System Name",
      accessor: "SystemName",
      textAlignColumn: "start",
    },
    {
      label: "System Description",
      accessor: "SystemDescription",
      textAlignColumn: "start",
    },
    {
      label: "Drawing Number",
      accessor: "DrawingNumber",
      textAlignColumn: "start",
    },
    {
      label: "Action",
      accessor: "Action",
      textAlignColumn: "center",
      render: (_, rowIndex) => (
        <div
          onClick={(e) => {
            e.preventDefault();
            const existingData = activeDrillList?.find(
              (item) =>
                item?.EquipmentSystemId ===
                equipmentSystemList?.[rowIndex]?.EquipmentSystemId
            );

            if (!existingData) {
              setActiveDrillId(
                equipmentSystemList?.[rowIndex]?.EquipmentSystemId
              );
              setOutSideData(null);
              setSelectOutSideData(null);
              setOutSideDataShow(false);
            }
          }}
          className="text-[#00B6FF] font-[600] text-[15px] cursor-pointer	underline underline-offset-4"
        >
          DrillDown
        </div>
      ),
    },
  ];

  const columns2 = [
    {
      label: "Item Number",
      accessor: "ItemNumber",
      textAlignColumn: "center",
      // render: (_, rowIndex) => <div>{data[rowIndex]?.model || "-"}</div>,
    },
    {
      label: "Part Number",
      accessor: "PartNumber",
      textAlignColumn: "center",
    },
    {
      label: "Part Name",
      accessor: "PartName",
      textAlignColumn: "start",
    },
    {
      label: "Required Qty",
      accessor: "RequiredQTY",
      textAlignColumn: "center",
    },
  ];

  const fetchModelsData = async (page) => {
    setIsLoading(true);
    try {
      const header = {
        authorization: `Bearer ${localStorage.getItem("vizy-bility-token")}`,
      };
      const url = `Parts/DrillDownEquipmentSystemById?equipmentSystemId=${activeDrillId}`;
      const data = await getModelsSystemSummary(url, null, header);

      const paginationHeader = data.headers.get("x-pagination");
      const pagination = paginationHeader ? JSON.parse(paginationHeader) : null;
      setEquipmentSystemList(data?.data?.EquipmentSystemList || []);
      setSystemPartsList(data?.data?.SystemPartsList || []);
      setPartSystemImage(data?.data?.PartSystemImage || "");
      if (
        equipmentSystemList?.length > 0 &&
        equipmentSystemList?.find(
          (item) => item?.EquipmentSystemId === activeDrillId
        )
      ) {
        setActiveDrillList([
          ...activeDrillList,
          equipmentSystemList?.find(
            (item) => item?.EquipmentSystemId === activeDrillId
          ) || {},
        ]);
      }
    } catch (error) {
      toast.error(error?.data);
      filterEquipmentSystems(activeDrillList, activeDrillId);
      // Logout();
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectPart = (id, index) => {
    let newData = activeDrillList.slice(0, index + 1);
    setActiveDrillList(newData);
    setActiveDrillId(id);
  };

  useEffect(() => {
    setActiveDrillId(location.state?.modelSystemData?.EquipmentSystemId);
    setActiveDrillList([location.state?.modelSystemData]);
  }, []);

  useEffect(() => {
    if (activeDrillId) {
      fetchModelsData();
    }
  }, [activeDrillId]);
  return (
    <div className="pl-[21px]">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <div
            className="text-[20px] sm:text-[26px] md:text-[36px]  font-[600] cursor-pointer	Vehicle Parts Lookup hover:text-[#00b6ff]"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate(
                `/dealers-portal/vehicle-parts-lookup/${location.state?.modelSystemData?.ModelId}`,
                {
                  state: { modelData: location.state?.modelSystemData }, // Replace with your actual state
                }
              );
            }}
          >
            {location?.state?.modelData?.Model} Parts Lookup
          </div>
          <div className="text-[14px] md:text-[24px] font-[600] flex items-center flex-wrap">
            {activeDrillList?.map((item, index) => {
              return index === 0 ? (
                <div
                  className="cursor-pointer  hover:text-[#00b6ff] mr-[6px]"
                  onClick={() =>
                    handleSelectPart(item?.EquipmentSystemId, index)
                  }
                >
                  {item?.SystemName}{" "}
                </div>
              ) : (
                <div
                  className="cursor-pointer  hover:text-[#00b6ff]"
                  onClick={() =>
                    handleSelectPart(item?.EquipmentSystemId, index)
                  }
                >
                  {` > ${item?.SystemName}`}
                </div>
              );
            })}
          </div>
        </div>

        <div className="hidden md:block">
          <OrderItemModel refresh={refresh} />
        </div>
        <div className="block md:hidden">
          <DealerPortalDrawer />
        </div>
      </div>

      {isLoading ? (
        <div className="bg-white min-h-[85vh] flex flex items-center justify-center	">
          <CircularProgress />
        </div>
      ) : (
        <>
          {equipmentSystemList?.length === 0 &&
          systemPartsList?.length === 0 &&
          !partSystemImage ? (
            <div class="col-span-1 h-[75vh] p-[30px] mt-[10px] flex items-center justify-center text-[25px] font-bold">
              No Data Available
            </div>
          ) : (
            <>
              <div className="py-4">
                {equipmentSystemList?.length === 0 ? (
                  <div className="bg-white min-h-[10vh] flex flex items-center justify-center	">
                    No Data Available
                  </div>
                ) : (
                  <div className="max-h-[40vh] overflow-y-auto">
                    <CustomTable
                      headers={columns}
                      data={equipmentSystemList}
                      maxWidth={"40vh"}
                      handleOutSideData={handleOutSideData}
                      selectOutSideData={selectOutSideData}
                    />
                  </div>
                )}
              </div>

              {outSideDataShow ? (
                outSideDataLoading ? (
                  <div className="bg-white min-h-[43vh] flex flex items-center justify-center	">
                    <CircularProgress />
                  </div>
                ) : outSideData?.SystemPartsList?.length === 0 &&
                  !outSideData?.PartSystemImage ? (
                  <div class="col-span-1 h-[35vh] p-[30px] mt-[10px] flex items-center justify-center text-[25px] font-bold">
                    No Data Available
                  </div>
                ) : (
                  <div class="grid grid-cols-1 md:grid-cols-3  gap-0 md:gap-4">
                    {outSideData?.PartSystemImage ? (
                      <div class="h-full bg-white flex items-center justify-center	max-h-[50vh] ">
                        <Zoom>
                          <div class="col-span-1 p-[30px] bg-white mt-[10px] text-center">
                            <img
                              src={outSideData?.PartSystemImage}
                              style={{ maxHeight: "40vh", margin: "auto" }}
                            />
                          </div>
                        </Zoom>
                      </div>
                    ) : (
                      <div class="col-span-1 max-h-[50vh] p-[30px] bg-white mt-[10px] flex items-center justify-center">
                        No Image Available
                      </div>
                    )}

                    <div class="col-span-2">
                      {outSideData?.SystemPartsList?.length === 0 ? (
                        <div className="bg-white min-h-[100%] flex flex items-center justify-center	">
                          No Data Available
                        </div>
                      ) : (
                        <div className="max-h-[50vh] h-full  bg-white">
                          <CustomTable
                            headers={columns2}
                            data={outSideData?.SystemPartsList}
                            maxWidth={"50vh"}
                            select={true}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )
              ) : systemPartsList?.length === 0 && !partSystemImage ? null : (
                <div class="grid grid-cols-1 md:grid-cols-3  gap-0 md:gap-4">
                  {partSystemImage ? (
                    <div class="h-full bg-white flex items-center justify-center	max-h-[50vh] ">
                      <Zoom>
                        <div class="col-span-1 p-[30px] bg-white mt-[10px] text-center">
                          <img
                            src={partSystemImage}
                            style={{ maxHeight: "40vh", margin: "auto" }}
                          />
                        </div>
                      </Zoom>
                    </div>
                  ) : (
                    <div class="col-span-1 max-h-[50vh] p-[30px] bg-white mt-[10px] flex items-center justify-center">
                      No Image Available
                    </div>
                  )}

                  <div class="col-span-2">
                    {systemPartsList?.length === 0 ? (
                      <div className="bg-white min-h-[100%] flex flex items-center justify-center	">
                        No Data Available
                      </div>
                    ) : (
                      <div className="max-h-[50vh] h-full bg-white">
                        <CustomTable
                          headers={columns2}
                          data={systemPartsList}
                          maxWidth={"50vh"}
                          select={true}
                          refresh={() => setRefresh(!refresh)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default VehiclePartsLookupInfo;
