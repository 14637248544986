import * as Yup from "yup";
import {
  Badge,
  Box,
  Card,
  CircularProgress,
  Divider,
  Modal,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import JoditEditorComponent from "./JoditEditorComponent";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { useState } from "react";
import { ApplicationId } from "../api/url";
import { postContactUs } from "../services/contactUsService";
import { toast } from "react-toastify";
import { useEffect } from "react";

const getJodit = (data) => {
  return `
  <table border="1" style="border-collapse: collapse;">
  <tr>
    <th><span style="color: rgba(0, 0, 0, 0.9); font-size: 15px;padding:10px;"><strong>Part Number</strong></span></th>
    <th><span style="color: rgba(0, 0, 0, 0.9); font-size: 15px;padding:10px;"><strong>Part Name</strong></span></th>
    <th><span style="color: rgba(0, 0, 0, 0.9); font-size: 15px;padding:10px;"><strong>QTY</strong></span></th>
  </tr>
  ${data
    ?.map((item) => {
      return `
  <tr>
    <td><span style="color: rgba(0, 0, 0, 0.9); font-size: 15px;padding:10px;">${
      item?.PartNumber || ""
    }</span></td>
    <td><span style="color: rgba(0, 0, 0, 0.9); font-size: 15px;padding:10px;">${
      item?.PartName || ""
    }</span></td>
    <td><span style="color: rgba(0, 0, 0, 0.9); font-size: 15px;padding:10px;">${
      item?.RequiredQTY || ""
    }</span></td>
  </tr>
  `;
    })
    .join("")}
</table>
  `;
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  deliveryAddress: Yup.string().required("Delivery address is required"),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
  borderRadius: "10px",
  minWidth: "60vw",
  height: "80vh",
  overflow: "auto",
};

const OrderItemModel = ({ outSideClick, setOutSideClick, refresh }) => {
  const isLogin = JSON?.parse(localStorage.getItem("client_info"));
  const [open, setOpen] = useState(false);
  const [cartItemCount, setCartItemCount] = useState(0);
  const [isSendMailLoading, setIsSendMailLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: isLogin?.given_name || "",
      email: isLogin?.emails?.[0] || "",
      deliveryAddress: "",
      Body: getJodit(JSON.parse(localStorage.getItem("add_cart"))),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSendEmail(values);
    },
  });

  const handleOpen = () => {
    formik.setValues({
      name: isLogin?.given_name || "",
      email: isLogin?.emails?.[0] || "",
      deliveryAddress: "",
      Body: getJodit(JSON.parse(localStorage.getItem("add_cart"))),
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (setOutSideClick) {
      setOutSideClick(false);
    }
  };

  const handleSendEmail = (value) => {
    const htmlForm = `
  <div style="font-size:25px">
    <div>
      <table>
        <tr style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;">${
            value?.name ?? ""
          }</td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${
            value?.email ?? ""
          }" style="color:#551A8B;text-decoration: underline;">${
      value?.email ?? ""
    }</a></td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Delivery Address</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;"></td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8">
          <td style="font-size:16px;color:#86848e;font-weight:bold;">${
            value?.deliveryAddress ?? ""
          }</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;"></td>
        </tr>

        <tr  style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Cart Items</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;"></td>
        </tr>
      </table>

      ${value?.Body}

      
    <div>
  </div>
`;

    setIsSendMailLoading(true);
    let payload = {
      HtmlContent: htmlForm,
      ApplicationId: ApplicationId,
      isShoppingCart: true,
      subject: "Part items order from Tuatara Dealer Portal",
    };
    postContactUs(payload)
      .then((res) => {
        toast.success(res?.data);
        setIsSendMailLoading(false);
        localStorage.removeItem("add_cart");
        handleClose();
      })
      .catch((err) => {
        toast.error(err?.data);
        setIsSendMailLoading(false);
      });
  };

  useEffect(() => {
    setCartItemCount(JSON.parse(localStorage.getItem("add_cart"))?.length || 0);
  }, [refresh, open]);

  useEffect(() => {
    if (outSideClick) {
      handleOpen();
    }
  }, [outSideClick]);

  return (
    <>
      <div className="cursor-pointer" onClick={handleOpen}>
        <div className="flex">
          <Badge badgeContent={cartItemCount} color="success">
            <div className="block md:hidden">
              <ShoppingCartIcon style={{ fontSize: "24px" }} />
            </div>
            <div className="hidden md:block">
              <ShoppingCartIcon style={{ fontSize: "40px" }} />
            </div>
          </Badge>

          <div className="block md:hidden text-[14px] font-[600] text-[#9FA4A6] cursor-pointer ml-[18px]">
            View Cart
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex justify-between mb-2">
            <div className="font-bold text-[20px]">Place Order</div>
            <div className="cursor-pointer" onClick={() => handleClose()}>
              <CloseIcon />
            </div>
          </div>
          <div className="mb-3" />

          <form onSubmit={formik.handleSubmit}>
            <Card
              bordered={false}
              style={{ overflow: "auto", height: "55vh", boxShadow: "none" }}
            >
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                />
                {formik.touched.name && formik.errors.name ? (
                  <p className="text-red-500 text-xs italic">
                    {formik.errors.name}
                  </p>
                ) : null}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className="text-red-500 text-xs italic">
                    {formik.errors.email}
                  </p>
                ) : null}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Delivery Address
                </label>
                <input
                  type="text"
                  name="deliveryAddress"
                  value={formik.values.deliveryAddress}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                />
                {formik.touched.deliveryAddress &&
                formik.errors.deliveryAddress ? (
                  <p className="text-red-500 text-xs italic">
                    {formik.errors.deliveryAddress}
                  </p>
                ) : null}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Body
                </label>

                <JoditEditorComponent
                  value={formik.values.Body}
                  onChange={(content) => formik.setValues({ Body: content })}
                />

                {formik.touched.Body && formik.errors.Body ? (
                  <p className="text-red-500 text-xs italic">
                    {formik.errors.Body}
                  </p>
                ) : null}
              </div>
            </Card>

            <div className="mb-7" />
            <div className="mx-auto flex items-center mt-[10px] text-center ">
              <button
                className="bg-[#78C15A] p-3 text-white mx-auto"
                type="submit"
              >
                {isSendMailLoading ? (
                  <CircularProgress style={{ color: "white" }} />
                ) : (
                  "Send Mail"
                )}
              </button>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
};
export default OrderItemModel;
