import React from "react";
import { useNavigate } from "react-router-dom";
import VehiclePartsIcon from "../../assets/svg/Vehicle.svg";
import InventoryListIcon from "../../assets/svg/InventoryList.svg";
import WarrantyIcon from "../../assets/svg/Warranty.svg";
import SettingIcon from "../../assets/svg/Setting.svg";

export const DealersPortal = () => {
  const navigate = useNavigate();
  const DealersPortalList = [
    {
      img: VehiclePartsIcon,
      title: "Vehicle Parts Lookup",
      link: "/dealers-portal/vehicle-parts-lookup",
    },
    {
      img: InventoryListIcon,
      title: "Inventory List",
      link: "/dealers-portal/inventory-list",
    },
    {
      img: WarrantyIcon,
      title: "Warranty",
      link: "/dealers-portal/vehicle-parts-lookup",
    },
    {
      img: SettingIcon,
      title: "Setting",
      link: "/dealers-portal/vehicle-parts-lookup",
    },
  ];

  return (
    <>
      <div className="max-w-[1440px] mx-auto px-[3rem] py-2 mt-[50px]">
        <div className="text-[#78C15A] text-[38px] font-[700] text-center">
          Welcome to the TUATARA Portal
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-[50px] mb-[120px]">
          {DealersPortalList?.map((item) => {
            return (
              <div
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate(item?.link);
                }}
                class="col-span-1 bg-white p-[80px] text-center flex flex-col items-center cursor-pointer"
              >
                <img src={item?.img} width={"64px"} />
                <div className="font-[600] text-[24px] mt-[30px]">
                  {item?.title}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
