import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.css";

import { Header } from "./components/header";
import { Model } from "./pages/models/ModelPage";
import { Accessories } from "./pages/accessories/AccessoriesPage";
import { Dealers } from "./pages/dealers/DealersPage";
import { Service } from "./pages/service/ServicePage";
import { Contact } from "./pages/contact/ContactPage";
import { DealersPortal } from "./pages/dealers-portal/Dealers-portalPage";
import { AboutPage } from "./pages/about/AboutPage";
import FooterComponent from "./components/footer/footerComponent";
import { MsalProvider } from "@azure/msal-react";
import { Detail } from "./pages/detail/detail";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./common/PrivateRoute";
import DealersHeader from "./components/header/DealersHeader";
import Layout from "./components/Layout";
import VehiclePartsLookup from "./pages/vehicle-parts-lookup/vehicle-parts-lookup";
import ShoppingCart from "./pages/shopping-cart/shopping-cart";
import VehiclePartsLookupDetails from "./pages/vehicle-parts-lookup-details/vehicle-parts-lookup-details";
import VehiclePartsLookupInfo from "./pages/vehicle-parts-lookup-info/vehicle-parts-lookup-info";
import ScrollToTopButton from "./common/ScrollToTopButton/scrollToTopButton";
import { Detail1500E } from "./pages/detail/detail1500E";
import { Detail1000EFI } from "./pages/detail/detail1000EFI";
import { ToastContainer } from "react-toastify";
import InventoryList from "./pages/inventory-list/inventory-list";

function App({ instance }) {
  // const isAuthenticated = true;
  return (
    <MsalProvider instance={instance}>
      <Router>
        <ToastContainer />
        <Layout>
          <Routes>
            <Route path="/" element={<Model />} />
            <Route path="/detail" element={<Detail1500E />} />
            <Route path="/1500E-detail" element={<Detail1500E />} />
            <Route path="/1000EFI-detail" element={<Detail1000EFI />} />
            <Route path="/accessories" element={<Accessories />} />
            <Route path="/dealers" element={<Dealers />} />
            <Route path="/service" element={<Service />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<Contact />} />
            <Route
              path="/dealers-portal"
              element={
                <PrivateRoute>
                  <DealersPortal />
                </PrivateRoute>
              }
            />
            <Route
              path="/dealers-portal/vehicle-parts-lookup"
              element={
                <PrivateRoute>
                  <VehiclePartsLookup />
                </PrivateRoute>
              }
            />

            <Route
              path="/shopping-cart"
              element={
                <PrivateRoute>
                  <ShoppingCart />
                </PrivateRoute>
              }
            />

            <Route
              path="/dealers-portal/vehicle-parts-lookup/:id"
              element={
                <PrivateRoute>
                  <VehiclePartsLookupDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/dealers-portal/vehicle-parts-lookup/:id/detail"
              element={
                <PrivateRoute>
                  <VehiclePartsLookupInfo />
                </PrivateRoute>
              }
            />

            <Route
              path="/dealers-portal/inventory-list"
              element={
                <PrivateRoute>
                  <InventoryList />
                </PrivateRoute>
              }
            />
          </Routes>
        </Layout>
      </Router>
    </MsalProvider>
  );
}

export default App;
