// Library imports
import * as Yup from "yup";
import React, { useRef, useState } from "react";
// Local imports
import bulldozer1 from "../../assets/png/bulldozer1.png";
import bulldozer2 from "../../assets/images/1000EFIImg/1000-EFI.png";
import bulldozer3 from "../../assets/png/bulldozer3.png";
import bulldozer4 from "../../assets/png/bulldozer4.png";
import bulldozer5 from "../../assets/png/bulldozer5.png";
import Attach from "../../assets/png/attach.png";
import location from "../../assets/png/location.png";
import PowerIcon from "../../assets/png/POWER.png";
import top_speed from "../../assets/png/top_speed.png";
import fully_loaded from "../../assets/png/fully_Loaded.png";
import "./Model.css";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import { postContactUs } from "../../services/contactUsService";
import { toast } from "react-toastify";
import { ApplicationId } from "../../api/url";

export const Model = () => {
  const navigate = useNavigate();
  const reCaptchaRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const AccSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Name"),
    email: Yup.string()
      .email("Enter Valid Email Address")
      .required("Please Enter E-mail Address"),
    message: Yup.string().required("Please Enter message"),
    reCaptcha: Yup.string().required("recaptcha is a required field"),
  });

  const Description = [
    {
      id: 1,
      title: "UNRIVALED VERSATILITY",
      description:
        "Tuatara UTVs are designed to cater to a wide range of riders and applications. Whether you're an adrenaline seeking trailblazer, a hardworking farmer, or an outdoor enthusiast, Tuatara offers UTVs with varying features engineered to withstand the harshest conditions and keep you going mile after mile.",
    },
  ];

  const Description2 = [
    {
      id: 2,
      title: "ALL STEEL CONSTRUCTION",
      description:
        "We wanted a vehicle that would last for decades so our engineering team insisted on an all-steel body with an industry best Electrophoresis Anti-Rust treatment. This is not a typical plastic UTV, this is a purpose-built electric vehicle designed with a frame to take on the constant abuse of unforgiving terrain and weather.",
    },
  ];

  const EquipmentData = [
    {
      id: 1,
      mainImg: bulldozer5,
      title: "THE ELECTRIC 1500E",
      redirectURL: "1500E-detail",
      dec: "The Tuatara 1500E is an all electric Utility Vehicle capable of a range of over 30 miles on one charge. With a load capacity of 2,200 pounds and a top speed of 27 MPH, this vehicle can handle any job thrown at it with ease.",
      images: [
        {
          id: 1,
          img: location,
          desc: "RANGE 30MILES",
        },
        {
          id: 2,
          img: top_speed,
          desc: "TOP SPEED 27MPH",
        },
        {
          id: 3,
          img: fully_loaded,
          desc: "LOAD CAP. 2,200LBS",
        },
      ],
    },
    {
      id: 2,
      mainImg: bulldozer2,
      title: "THE GAS 1000EFI",
      redirectURL: "1000EFI-detail",
      dec: "The Tuatara 1000EFI is a gas-powered Utility Vehicle with over 70 HP and a top speed of 50 MPG. It can carry 2,200 lbs of whatever you need, across any terrain, for any job.",
      images: [
        {
          id: 1,
          img: PowerIcon,
          desc: "POWER 70HP",
        },
        {
          id: 2,
          img: top_speed,
          desc: "TOP SPEED 50MPG",
        },
        {
          id: 3,
          img: fully_loaded,
          desc: "LOAD CAP. 2,200LBS",
        },
      ],
    },
    // {
    //   id: 3,
    //   mainImg: bulldozer4,
    //   title: "THE ELECTRIC DUAL CAB / DUAL MOTOR",
    //   dec: "Introducing Tuatara’s latest revolution in off-road excitement the Electric 6-Seater Dual Cabin / Dual Motor UTV! With power pulsing through each axle courtesy of dual motors, this beast is ready to conquer any terrain with unrivaled efficiency and control. Power up and pave your path with the ultimate in off-road innovation. ",
    //   images: [
    //     {
    //       id: 1,
    //       img: location,
    //       desc: "RANGE 30MILES",
    //     },
    //     {
    //       id: 2,
    //       img: top_speed,
    //       desc: "TOP SPEED 27MPH",
    //     },
    //     {
    //       id: 3,
    //       img: fully_loaded,
    //       desc: "LOAD CAP. 2,200LBS",
    //     },
    //   ],
    // },
  ];

  const EquipmentComponent = ({ items, index }) => {
    const navigate = useNavigate();

    return (
      <div className="grid grid-cols-1 lg:grid-cols-2 items-center mb-[70px]">
        <div className={`block ${index % 2 === 1 ? null : "md:hidden "}`}>
          <img
            src={items?.mainImg}
            className="h-[350px] 2xl:h-[604px] xl:h-[480px] lg:h-[430px] mx-auto md:h-[400px] sm:h-[380px] w-auto"
          />
        </div>

        <div className="mx-auto lg:my-0 my-[35px]">
          <p className="font-bold text-[#000] font-bold text-[14px] 2xl:text-[38px] xl:text-[34px] lg:text-[30px] md:text-[26px] sm:text-[24px] text-center">
            {items?.title}
          </p>
          <p className="text-[14px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[14px] text-center">
            {items?.dec}
          </p>
          <div className="flex flex-row items-end gap-[40px]">
            {items?.images?.map((item, index) => (
              <div key={index}>
                <img
                  src={item?.img}
                  className="h-[75px] my-5 xl:h-[135px] lg:h-[115px] md:h-[95px] sm:h-[75px] mb-3 mx-auto"
                />
                <p className="font-semibold text-[14px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[14px] text-center">
                  {item?.desc}
                </p>
              </div>
            ))}
          </div>

          <div className="mx-auto flex items-center my-[40px]">
            <button
              className="bg-[#78C15A] p-3 text-white mx-auto"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate(items?.redirectURL);
              }}
            >
              LEARN MORE
            </button>
          </div>
        </div>

        <div className={`hidden ${index % 2 === 1 ? null : "md:block"}`}>
          <img
            src={items?.mainImg}
            className="h-[250px] 2xl:h-[542px] xl:h-[500px] lg:h-[450px] ml-auto md:h-[400px] sm:h-[300px] w-auto"
          />
        </div>
      </div>
    );
  };

  const handleSubmitForm = (value, resetForm) => {
    const htmlForm = `
  <div style="font-size:25px">
    <div>
      <table>
        <tr style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;">${
            value?.name ?? ""
          }</td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${
            value?.email ?? ""
          }" style="color:#551A8B;text-decoration: underline;">${
      value?.email ?? ""
    }</a></td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;">${
            value?.phone ?? ""
          }</td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Message</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;padding-right:40px;max-width: 2600px;line-break: anywhere;">${
            value?.message ?? ""
          }</td>
        </tr>
      </table>
    <div>
  </div>
`;

    setIsLoading(true);
    let payload = {
      HtmlContent: htmlForm,
      ApplicationId: ApplicationId,
      subject: "Contact Us form submitted on Tuatara website",
    };
    postContactUs(payload)
      .then((res) => {
        toast.success(res?.data);
        reCaptchaRef?.current?.reset();
        setIsLoading(false);

        resetForm();
      })
      .catch((err) => {
        toast.error(err?.data);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <div className="home_page top-0 right-1/2">
        <div className="font-bold text-[38px] text-[#fff] text-center">
          REAL STEEL DETERMINATION
        </div>
        <div className="font-[500] text-[22px] text-[#fff] text-center ">
          Premium UTVs for any application
        </div>
        <div className="mx-auto flex items-center mt-[10px] text-center ">
          <button
            className="bg-[#78C15A] p-3 text-white mx-auto"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/contact");
            }}
          >
            Contact Us
          </button>
        </div>
      </div>

      <div className="max-w-[1440px] mx-auto px-[3rem]">
        <div className="max-w-[750px] m-auto">
          {Description.map((item) => (
            <div className="my-[3rem] flex flex-col gap-4">
              <p className="text-[#78C15A] font-bold text-[14px] 2xl:text-[38px] xl:text-[34px] lg:text-[30px] md:text-[26px] sm:text-[24px] text-center">
                {item?.title}
              </p>
              <p className="text-[14px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[14px] text-center">
                {item?.description}
              </p>
            </div>
          ))}
        </div>
        {EquipmentData.map((items, index) => {
          return <EquipmentComponent items={items} index={index} />;
        })}

        <div className="max-w-[750px] m-auto">
          {Description2.map((item) => (
            <div className="my-[3rem] flex flex-col gap-4">
              <p className="text-[#78C15A] font-bold text-[14px] 2xl:text-[38px] xl:text-[34px] lg:text-[30px] md:text-[26px] sm:text-[24px] text-center">
                {item?.title}
              </p>
              <p className="text-[14px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[14px] text-center">
                {item?.description}
              </p>
            </div>
          ))}
        </div>

        <div className="max-w-[750px] m-auto">
          <div className="my-[3rem] flex flex-col gap-4">
            <p className="text-[#78C15A] font-bold text-[14px] 2xl:text-[38px] xl:text-[34px] lg:text-[30px] md:text-[26px] sm:text-[24px] text-center">
              CONTACT US
            </p>

            <div className="bg-[#fff] rounded-[30px] p-[60px]">
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  phone: "",
                  message: "",
                  reCaptcha: "",
                }}
                validationSchema={AccSchema}
                onSubmit={(values, { resetForm }) => {
                  handleSubmitForm(values, resetForm);
                }}
              >
                {({
                  errors,
                  touched,
                  values,
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="mb-[25px]">
                      <input
                        placeholder="Name"
                        className="w-full text-[12px] pt-[8.96px] pr-[116.49px] pb-[16.13px] pl-[8.96px] border-2 rounded"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                      />
                      {errors.name && touched.name && (
                        <div
                          style={{ fontSize: 14, textAlign: "left" }}
                          className=" mt-1 text-red-600"
                        >
                          {errors.name}
                        </div>
                      )}
                    </div>
                    <div className="mb-[25px]">
                      <input
                        placeholder="Email Address"
                        className="w-full text-[12px] pt-[8.96px] pr-[116.49px] pb-[16.13px] pl-[8.96px] border-2 rounded"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      {errors.email && touched.email && (
                        <div
                          style={{ fontSize: 14, textAlign: "left" }}
                          className=" mt-1 text-red-600"
                        >
                          {errors.email}
                        </div>
                      )}
                    </div>
                    <div className="mb-[25px]">
                      <input
                        placeholder="Phone Number"
                        className="w-full text-[12px] pt-[8.96px] pr-[116.49px] pb-[16.13px] pl-[8.96px] border-2 rounded"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                      />
                      {errors.phone && touched.phone && (
                        <div
                          style={{ fontSize: 14, textAlign: "left" }}
                          className=" mt-1 text-red-600"
                        >
                          {errors.phone}
                        </div>
                      )}
                    </div>

                    <div className="mb-[25px]">
                      <textarea
                        rows="6"
                        placeholder="Message/Questions"
                        className="w-full text-[12px] pt-[8.96px] pr-[116.49px] pb-[16.13px] pl-[8.96px] border-2 rounded"
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                      />
                      {errors.message && touched.message && (
                        <div
                          style={{ fontSize: 14, textAlign: "left" }}
                          className=" mt-1 text-red-600"
                        >
                          {errors.message}
                        </div>
                      )}
                    </div>

                    <div className="flex items-center justify-between mb-[24px]">
                      <div className="text-[12px] flex justify-between items-center">
                        <div className="flex mr-[5px]">
                          <img src={Attach} width={"26px"} />{" "}
                        </div>
                        Attach Files
                      </div>
                      <div className="text-[10px]">Attachment(0)</div>
                    </div>
                    <div className="text-center flex items-center justify-center flex-col my-5">
                      <ReCAPTCHA
                        ref={reCaptchaRef}
                        size="normal"
                        sitekey={"6LdhUSMqAAAAANByfdbSyhgGZS1-SeDIVDPAq7rb"}
                        onChange={(captchaCode) => {
                          setFieldValue("reCaptcha", captchaCode);
                          // onReCAPTCHAChange
                        }}
                      />

                      {errors.reCaptcha && touched.reCaptcha && (
                        <div
                          style={{ fontSize: 14, textAlign: "left" }}
                          className=" mt-1 text-red-600"
                        >
                          {errors.reCaptcha}
                        </div>
                      )}
                    </div>

                    <div className="text-center max-w-[400px] m-auto">
                      <button
                        className={`bg-[#78C15A] p-3 text-white mx-auto w-[100%] flex items-center justify-center ${
                          isLoading ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <>
                            <svg
                              className="animate-spin h-5 w-5 mr-2 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8v8H4z"
                              ></path>
                            </svg>
                            SENDING...
                          </>
                        ) : (
                          "SEND"
                        )}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>

              <div className="text-[10px] mt-[34px] text-center">
                The Site is protected by reCAPTCHA and the Google Privacy Policy
                and Terms of Services apply.
              </div>
            </div>
          </div>
        </div>

        <p className="text-[#78C15A] my-5 text-[18px] xl:text-[28px] lg:text-[24px] md:text-[20px] sm:text-[18px] font-semibold text-center">
          FIND A DEALER
        </p>
        <div className="flex items-center">
          <button className="bg-[#F3F3F4] p-3 mx-auto text-[#000] m-auto mb-[30px]">
            ZIP Code
          </button>
        </div>
      </div>

      <div className="w-auto h-[500px] xl:h-[580px] lg:h-[550px] md:h-[520px] sm:h-[490px] ">
        <iframe
          src="https://www.google.com/maps/d/embed?mid=1ZO6e7060FsrkE0LdcDi1c4-ReoNSWgE&ehbc=2E312F&ll=30.33134465477491%2C-97.909776&z=6"
          width="100%"
          height="100%"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        />
      </div>

      <div className="max-w-[1440px] mx-auto px-5 mt-8 mb-6 flex justify-between flex-wrap">
        <div className="w-content mb-4">
          <div>
            <div className="text-[24px] font-bold uppercase">Odessa</div>
            <p className="text-[14px] font-medium">
              8870 TX-338 Loop
              <br />
              Odessa, TX 79764
            </p>
            <a
              href="tel: 432-276-5885"
              className="text-[24px] font-bold uppercase mt-2  hover:text-[#25538b]"
            >
              432-276-5885
            </a>
          </div>
        </div>
        <div className="w-content mb-4">
          <div className="text-[24px] font-bold uppercase">Hickory Creek</div>
          <p className="text-[14px] font-medium">
            8560 S Interstate 35
            <br />
            Hickory Creek, TX 75065
          </p>
          <a
            href="tel: 469-444-8323"
            className="text-[24px] font-bold uppercase mt-2  hover:text-[#25538b]"
          >
            (469) 444-8323
          </a>
        </div>
        <div className="w-content mb-4">
          <div className="text-[24px] font-bold uppercase">Van Vleck</div>
          <p className="text-[14px] font-medium">
            10182 SH 35 N
            <br />
            Van Vleck, TX 77482
          </p>
          <a
            href="tel: 979-307-7040"
            className="text-[24px] font-bold uppercase mt-2 hover:text-[#25538b]"
          >
            (979) 307-7040
          </a>
        </div>
        <div className="w-content mb-4">
          <div className="text-[24px] font-bold uppercase">Channelview</div>
          <p className="text-[14px] font-medium">
            15221 Market St
            <br />
            Channelview, TX 77530
          </p>
          <a
            href="tel: 281-417-2224"
            className="text-[24px] font-bold uppercase mt-2  hover:text-[#25538b]"
          >
            (281) 417-2224
          </a>
        </div>
      </div>
    </div>
  );
};
