import { useNavigate } from "react-router-dom";
import CustomTable from "../../common/Table";
import "./vehicle-parts-lookup.css";
import DealerPortalDrawer from "../../components/Layout/DealerPortalDrawer";
import { getModel } from "../../services/getModels";
import { useEffect, useState } from "react";
import Pagination from "../../common/Pagination";
import { CircularProgress } from "@mui/material";
import { useMsal } from "@azure/msal-react";

const VehiclePartsLookup = () => {
  const { instance, accounts } = useMsal();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const columns = [
    {
      label: "Model",
      accessor: "Model",
      textAlignColumn: "center",
      render: (_, rowIndex) => <div>{data[rowIndex]?.Model || "-"}</div>,
    },
    {
      label: "Description",
      accessor: "ModelDescription",
      textAlignColumn: "center",
      render: (_, rowIndex) => (
        <div
          dangerouslySetInnerHTML={{
            __html: data[rowIndex]?.Description || "-",
          }}
        />
      ),
    },
    {
      label: "Start Year",
      accessor: "StartYear",
      textAlignColumn: "center",
    },
    {
      label: "End Year",
      accessor: "EndYear",
      textAlignColumn: "center",
    },
    {
      label: "Serial Start",
      accessor: "StartSerial",
      textAlignColumn: "center",
    },
    {
      label: "Serial End",
      accessor: "EndSerial",
      textAlignColumn: "center",
    },
    {
      label: "Action",
      accessor: "Action",
      textAlignColumn: "center",
      render: (_, rowIndex) => (
        <div
          onClick={() => {
            window.scrollTo(0, 0);
            navigate(
              `/dealers-portal/vehicle-parts-lookup/${data[rowIndex]?.ModelId}`,
              {
                state: { modelData: data[rowIndex] }, // Replace with your actual state
              }
            );
          }}
          className="text-[#00B6FF] font-[600] text-[15px] cursor-pointer	underline underline-offset-4"
        >
          Search
        </div>
      ),
    },
  ];

  const Logout = async () => {
    try {
      localStorage.removeItem("vizy-bility-token");
      localStorage.removeItem("client_info");
      await instance.logoutRedirect({
        postLogoutRedirectUri: window.location.origin, // Redirect after logout
      });
      window.scrollTo(0, 0);
      navigate("/");
    } catch (error) {
      console.error(error);
    }
  };

  const fetchModelsData = async (page) => {
    setIsLoading(true);
    try {
      const header = {
        authorization: `Bearer ${localStorage.getItem("vizy-bility-token")}`,
      };
      const payload = {
        IncludeCount: true,
        PageNumber: page,
        Filter: {
          All: null,
          ReturnDocNumber: null,
          PartNumber: null,
          ReturnDocHeaderId: null,
          MakeName: ["Tuatara"],
        },
        Sort: "",
        Role: "SalesManager",
        Operator: "OR",
        PaginationOperator: "AND",
        PageSize: 10,
      };
      const url = "CRMAdmin/GetPagedModels";
      const data = await getModel(url, payload, header);
      const pagination = data?.headers
        ? JSON.parse(data?.headers?.get("x-pagination"))
        : {};
      setData(data?.data || []);
      setTotalPages(pagination?.TotalPages);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("error", error);
      Logout();
    }
  };

  useEffect(() => {
    fetchModelsData(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="pl-[21px]">
      <div className="flex  justify-between">
        <div className="text-[20px] sm:text-[26px] md:text-[36px] font-[600]">
          Vehicle Parts Lookup
        </div>
        <div className="block md:hidden">
          <DealerPortalDrawer />
        </div>
      </div>
      <div className="mx-auto py-4">
        {isLoading ? (
          <div className="bg-white min-h-[85vh] flex flex items-center justify-center	">
            <CircularProgress />
          </div>
        ) : (
          <div className="bg-white min-h-[85vh] flex flex-col justify-between">
            <CustomTable headers={columns} data={data} />
            <div className="p-4 flex justify-end">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default VehiclePartsLookup;
