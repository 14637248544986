import { useNavigate } from "react-router-dom";
import CustomTable from "../../common/Table";
import "./shopping-cart.css";
import DealerPortalDrawer from "../../components/Layout/DealerPortalDrawer";
import { getModel } from "../../services/getModels";
import { useEffect, useState } from "react";
import Pagination from "../../common/Pagination";
import { CircularProgress } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const ShoppingCart = () => {
  const [data,setData] =useState([]);

  useEffect(()=>{
    setData(JSON.parse(localStorage.getItem("add_cart")) || [])
  },[JSON.parse(localStorage.getItem("add_cart"))])

  const columns = [
    {
      label: "Item Number",
      accessor: "ItemNumber",
      textAlignColumn: "center",
    },
    {
      label: "Part Number",
      accessor: "PartNumber",
      textAlignColumn: "center",
    },
    {
      label: "Part Name",
      accessor: "PartName",
      textAlignColumn: "start",
    },
    {
      label: "Required Qty",
      accessor: "RequiredQTY",
      textAlignColumn: "center",
    },
    {
      label: "Delete",
      accessor: "Delete",
      textAlignColumn: "center",
      render: (_, rowIndex) => (
        <div
          onClick={() => {
            removeItem(data[rowIndex]?.PartNumber);
          }}
          className="text-red font-[600] text-[15px] cursor-pointer	underline underline-offset-4"
        >
          <DeleteOutlineIcon />
        </div>
      ),
    },
  ];

  const removeItem = (PartNumber) => {
    const removeItem = data?.filter((item)=>item.PartNumber !==PartNumber)
    localStorage.setItem("add_cart", JSON.stringify(removeItem));
  };

  return (
    <div className="pl-[21px]">
      <div className="flex  justify-between">
        <div className="text-[20px] sm:text-[26px] md:text-[36px] font-[600]">
          Shopping Cart
        </div>
        <div className="block md:hidden">
          <DealerPortalDrawer />
        </div>
      </div>
      <div className="mx-auto py-4">
        {data?.lgnth > 0 ? (
          <div class="col-span-1 h-[75vh] p-[30px] mt-[10px] flex items-center justify-center text-[25px] font-bold">
            No Data Available
          </div>
        ) : (
          <div className="bg-white min-h-[85vh] flex flex-col justify-between">
            <CustomTable headers={columns} data={data} />
          </div>
        )}
      </div>
    </div>
  );
};
export default ShoppingCart;
