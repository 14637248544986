import AddOnsImg from "../../assets/images/Add_ons.png";
import PartOne from "../../assets/images/FULL_LENGTH_DOOR.png";
import PartTwo from "../../assets/images/REAR_CABIN_COVER.png";
import PartThree from "../../assets/images/NO_CAB_OPTION.png";
import PartFour from "../../assets/images/TRAILER.png";
import HeadingTitle from "../../common/headingTitle";

const AddOns = () => {
  return (
    <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
      <div class="col-span-2">
        <div className="mb-[40px]">
          <HeadingTitle title={"ADD ONS"} />
        </div>
        <div>
          <img src={AddOnsImg} />
        </div>
      </div>
      <div class="col-span-2">
        <div class="grid grid-cols-2 md:grid-cols-4 gap-6 h-full">
          <div class="col-span-2 bg-[#fff] text-center px-[20px]">
            <img src={PartOne} className="mx-auto" />
            <div className="font-bold text-[20px] mt-4">FULL LENGTH DOOR</div>
          </div>
          <div class="col-span-2 bg-[#fff] text-center px-[20px]">
            <img src={PartTwo} className="mx-auto" />
            <div className="font-bold text-[20px] mt-4">REAR CABIN COVER</div>
          </div>
          <div class="col-span-2 bg-[#fff] text-center px-[20px]">
            <img src={PartFour} className="mx-auto" />
            <div className="font-bold text-[20px] mt-4">
            TRAILER
            </div>
          </div>
          <div class="col-span-2 bg-[#fff] text-center px-[20px]">
            <img src={PartThree} className="mx-auto" />
            <div className="font-bold text-[20px] mt-4">NO CAB OPTION</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddOns;
