import "./detail.css";
import React from "react";
import ProductComponents from "../../components/DetailComponents/productComponents";
import bulldozer5 from "../../assets/png/bulldozer5.png";
import location from "../../assets/png/location.png";
import top_speed from "../../assets/png/top_speed.png";
import fully_loaded from "../../assets/png/fully_Loaded.png";
import FeaturesComponents from "../../components/DetailComponents/featuresComponents";
import AddOns from "../../components/DetailComponents/addOns";
import ModularCab from "../../components/DetailComponents/modularCab";
import Slider from "react-slick";
import HeadingTitle from "../../common/headingTitle";

const ProductData = {
  id: 1,
  mainImg: bulldozer5,
  title: "THE ELECTRIC 1500E",
  dec: "The Tuatara 1500E is an all electric Utility Vehicle capable of a range of over 30 miles on one charge. With a load capacity of 2,200 pounds and a top speed of 27 MPH, this vehicle can handle any job thrown at it with ease.",
  images: [
    {
      id: 1,
      img: location,
      desc: "RANGE 30MILES",
    },
    {
      id: 2,
      img: top_speed,
      desc: "TOP SPEED 27MPH",
    },
    {
      id: 3,
      img: fully_loaded,
      desc: "LOAD CAP. 2,200LBS",
    },
  ],
};

const ResponsiveTable = ({ data }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full border-collapse border-spacing-2 table">
        <tbody>
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <tr className="border border-black">
                <td className="py-4 px-5 text-xl text-green-600 font-bold border-r border-black">
                  {item.key}
                </td>
                <td className="py-4 px-5 text-lg border-black">{item.value}</td>
              </tr>
              <tr className="spacer">
                <td colSpan="2">
                  <div className="h-2" />
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const CarouselData = [
  {
    title: "FARMER VETERAN COALITION MEMBER REBATE",
    description: "CUSTOMER INSTANT REBATE FOR FARMER VETERAN COALITION MEMBERS",
    date: "31/12/2024",
  },
  {
    title: "2024 EQUINE MEMBERSHIP PURCHASING PROGRAM",
    description:
      "SAVE WITH YOUR 2024 EQUINE MEMBERSHIP ON COMPACT/UTILITY AG TRACTORS, TURF AND RTV PRODUCTS!",
    date: "31/12/2024",
  },
  {
    title: "FARMER VETERAN COALITION MEMBER REBATE",
    description: "STAND ALONE FINANCING INSTALLMENT PROGRAM",
    date: "31/12/2024",
  },
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 963,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const Detail = () => {
  const PowerData = [
    { key: "Model:", value: "Tuatara 1500E" },
    { key: "Top Speed:", value: "Up to 27 MPH" },
    { key: "Motor:", value: "15KW 120V" },
    { key: "Max. Torque (Nm):", value: "90" },
    { key: "Cooling System:", value: "Liquid Cooled Motor" },
    { key: "Battery Type:", value: "ZeroNox Lithium Iron Phosphate" },
  ];

  const PerformanceData = [
    { key: "Battery:", value: "12.1KWh" },
    { key: "Charge Time (110V):", value: "6+HRS" },
    { key: "Charge Time (220V):", value: "3.5HRS" },
    { key: "Transmission:", value: "Automatic" },
    {
      key: "Final Drive:",
      value:
        "Shaft drive, 2WD & 4WD Selectable, differential lock front and rear individually selectable",
    },
  ];

  const CapacityData = [
    { key: "Seats with Seat Belts:", value: "3 People" },
    { key: "Hitch Capacity:", value: "2,200 lbs" },
    { key: "Winch Pulling Capacity:", value: "4,500 lbs" },
    { key: "Towing Capacity:", value: "2,200 lbs" },
    { key: "Gross Vehicle Weight Rating:", value: "4,560 lbs" },
    { key: "Total Load Capacity:", value: "2,200 lbs" },
    { key: "Lift Cargo Bed:", value: "1,200 lbs" },
    { key: "Front Rack:", value: "330 lbs" },
    { key: "Roof Rack:", value: "110 lbs" },
    { key: "Passengers:", value: "530 lbs" },
  ];

  const MaterialData = [
    { key: "Construction:", value: "Steel" },
    { key: "Tires:", value: "26 x 11 - 12 CST (All Terrain)" },
    { key: "Suspension (Front):", value: "MacPherson Strut" },
    { key: "Suspension (Rear):", value: "Independent Double Wish bone" },
    {
      key: "Brakes:",
      value: "Dual Circuit Brake System with Regenerative Braking",
    },
  ];

  const DimensionsData = [
    { key: "Vehicle Weight (Empty):", value: "2,360 lbs" },
    { key: "Ground Clearance:", value: "12”" },
    { key: "Wheel Base:", value: "97”" },
    { key: "Overall Height:", value: "82.8”" },
    { key: "Overall Width:", value: "60”" },
    { key: "Overall Length:", value: "141”" },
    { key: "Outside Bed Width:", value: "61.2”" },
    { key: "Outside Bed Length:", value: "67.2”" },
    { key: "Bed Height:", value: "12”" },
  ];

  return (
    <>
      {/* <div className="px-6 sm:px-10 md:px-12 lg:px-16 xl:px-20"> */}
      <div className="max-w-[1440px] mx-auto my-12 px-[3rem]">
        <div className="bg-[#221F1F] text-[#FFFFFF] text-xl py-4 rounded-full text-center">
          Check Out Our Finance Deals!
        </div>

        <div className="mt-20 mb-10">
          <ProductComponents ProductData={ProductData} />
        </div>

        <div className="border-t-2 border-black pt-12">
          <div className="mb-[40px]">
            <HeadingTitle
              title={"FEATURES & DETAILS"}
              center={"!text-center"}
            />
          </div>
          <div>
            <FeaturesComponents />
          </div>
        </div>

        <div className="mt-12">
          <AddOns />
        </div>

        <div className="pt-12">
          <div className="mb-12">
            <HeadingTitle title={"MODULAR CAB"} center={"!text-center"} />
          </div>
        </div>
        <div>
          <div>
            <ModularCab />
          </div>
        </div>
      </div>
      {/* </div> */}

      <div className="mt-12 bg-[#2f2d2ec7] py-24">
        <div className="max-w-[1440px] mx-auto px-[3rem]">
          {/* <div className="px-6 sm:px-10 md:px-12 lg:px-16 xl:px-20"> */}
          <div className="relative pt-[56.25%] overflow-hidden max-w-full bg-black">
            <iframe
              className="absolute top-0 left-0 w-full h-full border-0"
              src="https://www.youtube.com/embed/bI5CctE_e7w?si=23QrLYCWxtVcCkK-"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
          {/* </div> */}
        </div>
      </div>

      <div className="max-w-[1440px] mx-auto px-[3rem]">
        <div className="my-12">
          <div>
            <HeadingTitle
              title={"POWER & PERFORMANCE"}
              center={"!text-center"}
            />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-10">
              <div className="col-span-1">
                <ResponsiveTable data={PowerData} />
              </div>
              <div className="col-span-1">
                <ResponsiveTable data={PerformanceData} />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-10">
            <div className="col-span-1">
              <div className="mb-10">
                <HeadingTitle title={"CAPACITY"} center={"!text-center"} />
              </div>
              <ResponsiveTable data={CapacityData} />
            </div>
            <div className="col-span-1">
              <div className="mb-10">
                <HeadingTitle title={"MATERIAL"} center={"!text-center"} />
              </div>
              <ResponsiveTable data={MaterialData} />
            </div>
            <div className="col-span-1">
              <div className="mb-10">
                <HeadingTitle title={"DIMENSIONS"} center={"!text-center"} />
              </div>
              <ResponsiveTable data={DimensionsData} />
            </div>
          </div>

          {/* <div className="slider-container mt-10">
            <Slider {...settings}>
              {CarouselData?.map((item, index) => (
                <div
                  key={index}
                  className="bg-white p-12 text-center mr-2 !flex flex-col !justify-between"
                >
                  <div>
                    <div className="font-bold text-xl text-[#78C15A]">
                      {item?.title}
                    </div>
                    <div className="font-medium text-lg text-black mt-2">
                      {item?.description}
                    </div>
                  </div>
                  <div className="mt-[85px]">
                    <button className="bg-[#78C15A] px-8 py-2 text-white">
                      VIEW DETAILS
                    </button>
                    <div className="font-semibold text-base text-black mt-[20px]">
                      OFFER ENDS {item?.date}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div> */}
        </div>
      </div>
    </>
  );
};
