import React from "react";

const ProductComponents = ({ ProductData }) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 items-center mb-14">
      <div className="block lg:hidden">
        <img
          src={ProductData?.mainImg}
          className="h-56 sm:h-72 md:h-80 lg:h-96 xl:h-[480px] 2xl:h-[604px] mx-auto w-auto"
          alt="Product"
        />
      </div>

      <div className="mx-auto my-8 lg:my-0">
        <p className="font-bold text-black text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-[46px] text-center lg:text-left">
          {ProductData?.title}
        </p>
        <p className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-[20px] text-center lg:text-left mt-4 leading-[40px]">
          {ProductData?.dec}
        </p>
        <div className="flex flex-col sm:flex-row items-center lg:items-start gap-10 mt-8">
          {ProductData?.images?.map((item, index) => (
            <div key={index} className="text-center">
              <img
                src={item?.img}
                className="h-20 sm:h-24 md:h-28 lg:h-32 xl:h-[135px] mx-auto mb-3"
                alt="Feature"
              />
              <p className="font-semibold text-sm sm:text-base md:text-lg lg:text-xl xl:text-[20px]">
                {item?.desc}
              </p>
            </div>
          ))}
        </div>

        <div className="mt-10 text-center lg:text-left">
          <button
            className="bg-[#78C15A] px-6 py-3 text-white font-semibold rounded-md"
            // onClick={() => navigate("/detail")}
          >
            SEE FULL SPECIFICATION
          </button>
        </div>
      </div>

      <div className="hidden lg:block">
        <img
          src={ProductData?.mainImg}
          className="h-80 lg:h-96 xl:h-[500px] 2xl:h-[542px] mx-auto w-auto"
          alt="Product"
        />
      </div>
    </div>
  );
};

export default ProductComponents;
