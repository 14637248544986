import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
  const handleChange = (event, value) => {
    onPageChange(value);
  };

  return (
    <Stack spacing={2}>
      <Pagination 
        count={totalPages} 
        page={currentPage} 
        onChange={handleChange} 
        variant="outlined" 
        shape="rounded" 
      />
    </Stack>
  );
};

export default PaginationComponent;
