export const NavItems = [
  {
    id: 1,
    path: "/",
    text: "HOME",
  },
  {
    id: 2,
    path: "/detail",
    text: "MODELS",
    subMenus: [
      {
        id: 21,
        path: "/1500E-detail",
        text: "1500E",
      },
      {
        id: 22,
        path: "/1000EFI-detail",
        text: "1000EFI",
      },
    ],
  },
  // {
  //   id: 3,
  //   path: "/accessories",
  //   text: "ACCESSORIES",
  // },
  // {
  //   id: 4,
  //   path: "/service",
  //   text: "SERVICE & SUPPORT",
  // },
  // {
  //   id: 5,
  //   path: "/about",
  //   text: "ABOUT",
  // },
  {
    id: 6,
    path: "/contact",
    text: "CONTACT",
  },
  {
    id: 7,
    path: "/dealer-portal",
    text: "DEALER PORTAL",
  },
];
