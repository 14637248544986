const HeadingTitle = ({ title, center }) => {
  return (
    <p
      className={`font-bold text-[#2F2D2E] text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-[46px] lg:text-left ${
        center ? "!text-center" : ""
      }`}
    >
      {title}
    </p>
  );
};
export default HeadingTitle;
