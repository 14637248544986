import { useState } from "react";
import Img1 from "../../assets/images/1500EFIImg/1500E-1.jpg";
import Img2 from "../../assets/images/1500EFIImg/1500E-2.png";
import Img3 from "../../assets/images/1500EFIImg/1500E-3.png";
import Img4 from "../../assets/images/1500EFIImg/1500E-4.jpg";

import Img5 from "../../assets/images/1000EFIImg/1000EFI-1.png";
import Img6 from "../../assets/images/1000EFIImg/1000EFI-2.jpg";
import Img7 from "../../assets/images/1000EFIImg/1000EFI-3.png";
import Img8 from "../../assets/images/1000EFIImg/1000EFI-4.jpg";
import EverdayPerformanceImg from "../../assets/images/Everday_performance.png";

const Card = ({ imageSrc, title, description }) => {
  return (
    <div className="w-full lg:max-w-full lg:flex shadow-lg my-4 p-[50px] bg-white">
      <div
        className="h-[350px] w-full lg:h-[350px] lg:w-[500px] flex-none text-center overflow-hidden"
        style={{
          backgroundImage: `url(${imageSrc})`,
          backgroundSize: "cover", // Ensure the image covers the container without stretching
          backgroundPosition: "center", // Center the image within the container
          backgroundRepeat: "no-repeat", // Prevent the image from repeating
        }}
        title="Card Image"
      ></div>

      <div className="bg-white p-4 flex flex-col justify-between leading-normal">
        <div className="mb-8">
          <div className="font-bold text-[#2F2D2E] text-2xl sm:text-[25px] md:text-[30px] lg:text-[34px] xl:text-[38px] lg:text-left mb-2">
            {title}
          </div>
          <p className="text-[#2F2D2E] text-[20px] leading-[40px]">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

const FeaturesComponents = ({is100EFI}) => {
  const [activeFeatures, setActiveFeatures] = useState(0);
  const FeaturesType = [
    {
      id: 0,
      type: "RUGGED PERFORMANCE",
      description:
        "Tuatara UTV’s are not built for asphalt, they’re built for adventure! Designed and tested on the trails and rocky terrain of New Zealand, each UTV has been battle-tested to ensure that it can handle whatever terrain you can throw at it.",
      img: is100EFI?Img5:Img1,
    },
    {
      id: 1,
      type: "ALL-STEEL DESIGN",
      description:
        "Tuatara UTV’s are built to last. With an all-steel chassis, these machines can take a punch and keep going. This means you can expect your vehicle to keep on going through the toughest conditions, take a hit, and still get you to where you need to go.",
      img: is100EFI?Img6:Img2,
    },
    {
      id: 2,
      type: "CUSTOMIZABLE CONFIGURATIONS",
      description:
        "Tuatara machines are just as adaptable as the animals they’re named after! With customization options from alternate cabs to longer beds, each Tuatara will be uniquely suited to just your needs- meaning you can have the perfect machine for your needs",
      img: is100EFI?Img7:Img3,
    },
    {
      id: 3,
      type: "WORRY-FREE FINANCING ",
      description:
        "With financing options available for all new Tuatara purchases, you can rest assured that your new machine is not costing you an arm and a leg. Bundled with our warranty options, you can count on a worry-free purchasing experience.",
      img: is100EFI?Img8:Img4,
    },
  ];

  return (
    <>
      <div className="flex flex-wrap mt-[40px]">
        {FeaturesType?.map((item, index) => {
          return (
            <button
              className={`p-3 mr-[40px] mb-[30px] ${
                activeFeatures === item?.id
                  ? `bg-[#78C15A] text-white font-bold`
                  : `bg-[#F6F6F6] text-[#7b7b7b] font-bold`
              }`}
              onClick={() => setActiveFeatures(item.id)}
            >
              {item?.type}
            </button>
          );
        })}
      </div>
      <div>
        <Card
          imageSrc={
            FeaturesType?.find((item) => item.id === activeFeatures)?.img
          }
          title={FeaturesType?.find((item) => item.id === activeFeatures)?.type}
          description={
            FeaturesType?.find((item) => item.id === activeFeatures)
              ?.description
          }
        />
      </div>
    </>
  );
};
export default FeaturesComponents;
