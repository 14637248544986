export const NavLinkClasses =
  "text-[14px] 2xl:text-[20px] xl:text-[16px] lg:text-[13px] md:text-[10px] sm:text-[9px] font-regular hover:cursor-pointer decoration-[#000] decoration-2 ";

export const NavLinkActiveClasses =
  "text-[12px] 2xl:text-[20px] xl:text-[16px] lg:text-[13px] md:text-[10px] sm:text-[9px] font-semibold hover:cursor-pointer decoration-[#000] decoration-2 underline underline-offset-8";

export const NavMenuClasses =
  " w-56 h-10 font-semibold text-[#171717] pl-2 flex items-center hover:cursor-pointer hover:bg-[#c6c6c6]";

export const NavMenuActiveClasses =
  " w-56 h-10 font-semibold text-[#171717] pl-2 flex items-center hover:cursor-pointer bg-[#09bd24]";
