import bulldozer5 from "../assets/png/bulldozer5.png";
import EFIImg from "../assets/images/1000EFIImg/1000-EFI.png"
import location from "../assets/png/location.png";
import PowerIcon from "../assets/png/POWER.png";
import top_speed from "../assets/png/top_speed.png";
import fully_loaded from "../assets/png/fully_Loaded.png";
import CabImg1000EFI0 from "../assets/images/1000EFIImg/1.jpg";
import CabImg1000EFI1 from "../assets/images/1000EFIImg/2.jpg";
import CabImg1000EFI2 from "../assets/images/1000EFIImg/3.jpg";
import CabImg1000EFI4 from "../assets/images/1000EFIImg/4.jpg";
import CabImg1000EFI5 from "../assets/images/1000EFIImg/5.jpg";
import CabImg1000EFI6 from "../assets/images/1000EFIImg/6.jpg";
import CabImg1000EFI7 from "../assets/images/1000EFIImg/7.jpg";
import CabImg1000EFI8 from "../assets/images/1000EFIImg/8.jpg";

import CabImg1500EFI0 from "../assets/images/1500EFIImg/1.jpg";
import CabImg1500EFI1 from "../assets/images/1500EFIImg/2.jpg";
import CabImg1500EFI2 from "../assets/images/1500EFIImg/3.jpg";
import CabImg1500EFI4 from "../assets/images/1500EFIImg/4.jpg";
import CabImg1500EFI5 from "../assets/images/1500EFIImg/5.jpg";
import CabImg1500EFI6 from "../assets/images/1500EFIImg/6.jpg";
import CabImg1500EFI7 from "../assets/images/1500EFIImg/7.jpg";
import CabImg1500EFI8 from "../assets/images/1500EFIImg/8.jpg";

export const Detail1000EFIPageData = {
  PowerData: [
    { key: "Model:", value: "Tuatara 1000 EFI" },
    { key: "Top Speed:", value: "50MPH" },
    { key: "Horse Power:", value: "69" },
    { key: "Max. Torque (Nm):", value: "93" },
    { key: "Cooling System:", value: "Liquid Cooled" },
    {
      key: "Final Drive:",
      value: "2WD or 4Wd Selectable Rear & Front Differential Locks",
    },
  ],

  PerformanceData: [
    { key: "Fuel Grade:", value: "High Octane Fuel (91 or Better)" },
    { key: "Starter:", value: "12v Electric" },
    { key: "Transmission:", value: "Manual (5 Forward, 1 Reverse)" },
    { key: "Clutch:", value: "Hydraulic" },
    {
      key: "Engine Model:",
      value:
        "Chery In-Line Vertical, Three Cylinder, Water Cooled, DOHC,12 Valve",
    },
  ],

  CapacityData: [
    { key: "Oil Capacity:", value: "3.5L (With Filter)" },
    { key: "Fuel Capacity:", value: "13.21 Gallons" },
    { key: "Seats with Seat Belts:", value: "3 People" },
    { key: "Hitch Capacity:", value: "2.000 lbs" },
    { key: "Winch Pulling Capacity:", value: "4,500 lbs" },
    { key: "Towing Capacity:", value: "2,200 lbs" },
    { key: "Gross Vehicle Weight Rating:", value: "4,550 lbs" },
    { key: "Total Load Capacity:", value: "2,200 lbs" },
    { key: "Lift Cargo Bed:", value: "1,200 lbs" },
    { key: "Front Rack:", value: "330 lbs" },
    { key: "Roof Rack:", value: "110 lbs" },
    { key: "Passengers:", value: "530 lbs" },
  ],

  MaterialData: [
    { key: "Construction:", value: "Steel" },
    { key: "Tires:", value: "26 x 11 - 12 CST (All Terrain)" },
    { key: "Suspension (Front):", value: "Independent Wishbone with Preload" },
    {
      key: "Suspension (Rear):",
      value: "Independent Double Wishbone with Preload",
    },
    { key: "Brakes:", value: "Dual Independent Hydraulic Disc Brakes" },
  ],

  DimensionsData: [
    { key: "Vehicle Weight (Empty):", value: `2,380 Ibs` },
    { key: "Ground Clearance:", value: `12"` },
    { key: "Wheel Base:", value: `97"` },
    { key: "Overall Height:", value: `6'9"` },
    { key: "Overall Width:", value: `5'` },
    { key: "Overall Length:", value: `11'` },
    { key: "Outside Bed Width:", value: `5'1"` },
    { key: "Outside Bed Length:", value: `5'6"` },
    { key: "Bed Height:", value: `12"` },
  ],
};

export const Detail1000EFIProductData = {
  id: 1,
  mainImg: EFIImg,
  title: "THE GAS 1000EFI",
  dec: "The Tuatara 1000EFI is a gas-powered Utility Vehicle with over 70 HP and a top speed of 50 MPG. It can carry 2,200 lbs of whatever you need, across any terrain, for any job.",
  images: [
    {
      id: 1,
      img: PowerIcon,
      desc: "POWER 70HP",
    },
    {
      id: 2,
      img: top_speed,
      desc: "TOP SPEED 50MPG",
    },
    {
      id: 3,
      img: fully_loaded,
      desc: "LOAD CAP. 2,200LBS",
    },
  ],
};

export const Detail1000EFIPageCarouselData = [
  {
    title: "FARMER VETERAN COALITION MEMBER REBATE",
    description: "CUSTOMER INSTANT REBATE FOR FARMER VETERAN COALITION MEMBERS",
    date: "31/12/2024",
  },
  {
    title: "2024 EQUINE MEMBERSHIP PURCHASING PROGRAM",
    description:
      "SAVE WITH YOUR 2024 EQUINE MEMBERSHIP ON COMPACT/UTILITY AG TRACTORS, TURF AND RTV PRODUCTS!",
    date: "31/12/2024",
  },
  {
    title: "FARMER VETERAN COALITION MEMBER REBATE",
    description: "STAND ALONE FINANCING INSTALLMENT PROGRAM",
    date: "31/12/2024",
  },
];

export const Detail1500EFIPageData = {
  PowerData: [
    { key: "Model:", value: "Tuatara 1500E" },
    { key: "Top Speed:", value: "Up to 27 MPH" },
    { key: "Motor:", value: "15KW 120V" },
    { key: "Max. Torque (Nm):", value: "90" },
    { key: "Cooling System:", value: "Liquid Cooled Motor" },
    { key: "Battery Type:", value: "Tuatara Lithium Iron Phosphate" },
  ],

  PerformanceData: [
    { key: "Battery:", value: "12.1KWh" },
    { key: "Charge Time (110V):", value: "6+HRS" },
    { key: "Charge Time (220V):", value: "3.5HRS" },
    { key: "Transmission:", value: "Automatic" },
    {
      key: "Final Drive:",
      value:
        "Shaft drive, 2WD & 4WD Selectable, differential lock front and rear individually selectable",
    },
  ],

  CapacityData: [
    { key: "Seats with Seat Belts:", value: "3 People" },
    { key: "Hitch Capacity:", value: "2,200 lbs" },
    { key: "Winch Pulling Capacity:", value: "4,500 lbs" },
    { key: "Towing Capacity:", value: "2,200 lbs" },
    { key: "Gross Vehicle Weight Rating:", value: "4,560 lbs" },
    { key: "Total Load Capacity:", value: "2,200 lbs" },
    { key: "Lift Cargo Bed:", value: "1,200 lbs" },
    { key: "Front Rack:", value: "330 lbs" },
    { key: "Roof Rack:", value: "110 lbs" },
    { key: "Passengers:", value: "530 lbs" },
  ],
  MaterialData: [
    { key: "Construction:", value: "Steel" },
    { key: "Tires:", value: "26 x 11 - 12 CST (All Terrain)" },
    { key: "Suspension (Front):", value: "MacPherson Strut" },
    { key: "Suspension (Rear):", value: "Independent Double Wish bone" },
    {
      key: "Brakes:",
      value: "Dual Circuit Brake System with Regenerative Braking",
    },
  ],

  DimensionsData: [
    { key: "Vehicle Weight (Empty):", value: "2,360 lbs" },
    { key: "Ground Clearance:", value: "12”" },
    { key: "Wheel Base:", value: "97”" },
    { key: "Overall Height:", value: "82.8”" },
    { key: "Overall Width:", value: "60”" },
    { key: "Overall Length:", value: "141”" },
    { key: "Outside Bed Width:", value: "61.2”" },
    { key: "Outside Bed Length:", value: "67.2”" },
    { key: "Bed Height:", value: "12”" },
  ],
};

export const Detail1500EFIProductData = {
  id: 1,
  mainImg: bulldozer5,
  title: "THE ELECTRIC 1500E",
  dec: "The Tuatara 1500E is an all electric Utility Vehicle capable of a range of over 30 miles on one charge. With a load capacity of 2,200 pounds and a top speed of 27 MPH, this vehicle can handle any job thrown at it with ease.",
  images: [
    {
      id: 1,
      img: location,
      desc: "RANGE 30MILES",
    },
    {
      id: 2,
      img: top_speed,
      desc: "TOP SPEED 27MPH",
    },
    {
      id: 3,
      img: fully_loaded,
      desc: "LOAD CAP. 2,200LBS",
    },
  ],
};

export const Detail1500EFIPageCarouselData = [
  {
    title: "FARMER VETERAN COALITION MEMBER REBATE",
    description: "CUSTOMER INSTANT REBATE FOR FARMER VETERAN COALITION MEMBERS",
    date: "31/12/2024",
  },
  {
    title: "2024 EQUINE MEMBERSHIP PURCHASING PROGRAM",
    description:
      "SAVE WITH YOUR 2024 EQUINE MEMBERSHIP ON COMPACT/UTILITY AG TRACTORS, TURF AND RTV PRODUCTS!",
    date: "31/12/2024",
  },
  {
    title: "FARMER VETERAN COALITION MEMBER REBATE",
    description: "STAND ALONE FINANCING INSTALLMENT PROGRAM",
    date: "31/12/2024",
  },
];

export const Detail1000EFIImg = [
  CabImg1000EFI0,
  CabImg1000EFI1,
  CabImg1000EFI2,
  CabImg1000EFI4,
  CabImg1000EFI5,
  CabImg1000EFI6,
  CabImg1000EFI7,
  CabImg1000EFI8,
];

export const Detail1500EFIImg = [
  CabImg1500EFI0,
  CabImg1500EFI1,
  CabImg1500EFI2,
  CabImg1500EFI4,
  CabImg1500EFI5,
  CabImg1500EFI6,
  CabImg1500EFI7,
  CabImg1500EFI8,
];
