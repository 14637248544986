import React, { useCallback, useEffect, useRef, useState } from "react";
import { NavItems } from "../../data/header";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { NavLinkClasses, NavLinkActiveClasses } from "../../common/styles";
import { dealerPortalStyle } from "../../common/dealerPortalStyle";
import { useMsal } from "@azure/msal-react";
import { Button, MenuItem, useTheme } from "@mui/material";
import WarningModal from "../../common/WarningModel";

const DropdownMenuItem = ({ menuItem }) => {
  const { text, subMenus } = menuItem;
  const buttonRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const subMenusNodes = menuItem?.subMenus?.map((subMenuItem) => {
    return (
      <MenuItem
        sx={{ background: "#fef5e8", py: "16px" }}
        onClick={() => {
          window.scrollTo(0, 0);
          navigate(subMenuItem?.path);
        }}
        key={subMenuItem.text}
        className={NavLinkClasses}
      >
        <div
          className={NavLinkClasses}
          style={{ fontSize: "20px", fontWeight: "bold", padding: "0px 15px" }}
        >
          {subMenuItem.text}
        </div>
      </MenuItem>
    );
  });

  const theme = useTheme();
  return (
    <>
      <div class="dropdown">
        <Button
          id={`menuItem-${text}`}
          // higher zIndex to make button show submenu when move mouse from another submenu
          sx={{
            zIndex: theme.zIndex.modal + 1,
            color: "#000",
            fontFamily: "Mont",
            fontWeight:
              location?.pathname === menuItem?.path ? "bold" : "regular",
            "&:hover": {
              backgroundColor: "#f0f8ff00",
            },
          }}
          ref={buttonRef}
          // onClick={() => {
          //   window.scrollTo(0, 0);
          //   navigate("/detail");
          // }}
          className="dropbtn"
        >
          <div
            className={
              location?.pathname === menuItem?.path
                ? NavLinkActiveClasses
                : NavLinkClasses
            }
          >
            {text}
          </div>
        </Button>
        <div class="dropdown-content-header" style={{ padding: "0px" }}>
          {subMenusNodes}
        </div>
      </div>
    </>
  );
};

export default function Navbar({ openWarning, setOpenWarning, handleLogin }) {
  const { instance, accounts } = useMsal();
  const location = useLocation();
  const navigate = useNavigate();
  const isLogin = JSON.parse(localStorage.getItem("client_info"));
  const [menuShowingDropdown, setMenuShowingDropdown] = useState("");
  const handleMenuShowingDropdownChange = useCallback((menuTitle) => {
    setMenuShowingDropdown(menuTitle);
  }, []);

  const handleCloseWarning = () => {
    setOpenWarning(false);
    localStorage.removeItem("OpenWarning");
  };

  const handleNavClick = (button) => {
    if (button.id === 7 && !isLogin) {
      handleLogin();
    } else if (button.id === 7) {
      window.scrollTo(0, 0);
      navigate("/dealers-portal");
    }
  };

  return (
    <nav className="flex items-center text-[#000] max-sm:hidden space-x-[10px] lg:space-x-[30px] xl:space-x-[40px] md:space-x-[20px] sm:space-x-[10px]">
      {NavItems.map((button) =>
        button?.subMenus ? (
          <DropdownMenuItem
            key={button}
            menuItem={button}
            menuShowingDropdown={menuShowingDropdown}
            setMenuShowingDropdown={handleMenuShowingDropdownChange}
          />
        ) : (
          <NavLink
            key={button.id}
            to={button.id === 7 ? "" : button.id === 6 ? "/contact" : "/"}
            onClick={
              button.id === 7
                ? (e) => {
                    e.preventDefault();

                    window.scrollTo(0, 0);
                    handleNavClick(button);
                  }
                : window.scrollTo(0, 0)
            }
            className={({ isActive }) =>
              `${
                location?.pathname === button?.path
                  ? NavLinkActiveClasses
                  : NavLinkClasses
              } ${button.id === 7 ? dealerPortalStyle : ""}`
            }
          >
            {button.text}
          </NavLink>
        )
      )}

      <WarningModal open={openWarning} handleClose={handleCloseWarning} />
    </nav>
  );
}
