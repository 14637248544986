import React from "react";
import { useLocation } from "react-router-dom";
import DealersHeader from "../header/DealersHeader";
import { Header } from "../header";
import FooterComponent from "../footer/footerComponent";
import DealersFooter from "../header/DealersFooter";
import DealerPortalDrawer from "./DealerPortalDrawer";
import ScrollToTopButton from "../../common/ScrollToTopButton/scrollToTopButton";

const Layout = ({ children }) => {
  const location = useLocation();
  const isDealersPortal = location.pathname === "/dealers-portal";
  const isVehiclePartsLookupPortal =
    location.pathname === "/dealers-portal/vehicle-parts-lookup" ||
    location.pathname.includes("/dealers-portal/vehicle-parts-lookup") ||
    location.pathname === "/dealers-portal/inventory-list" ||
    location.pathname.includes("/dealers-portal/inventory-list") ||
    location.pathname === "/shopping-cart"

  return (
    <div>
      {isVehiclePartsLookupPortal ? (
        <div className="p-[30px] flex">
          <div className="hidden md:block">
            <DealerPortalDrawer />
          </div>
          <main className="w-full">{children}</main>
        </div>
      ) : (
        <>
          {isDealersPortal ? <DealersHeader /> : <Header />}
          <main>{children}</main>
          {isDealersPortal ? <DealersFooter /> : <FooterComponent />}
        </>
      )}

      <ScrollToTopButton />
    </div>
  );
};

export default Layout;
