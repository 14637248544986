import React from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const WarningModal = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="warning-modal-title"
      aria-describedby="warning-modal-description"
      className="flex justify-center items-center"  // Center the modal using Flexbox
    >
      <Box className="bg-white p-6 rounded-md shadow-lg w-1/3 outline-none">
        <Box className="flex items-center justify-between mb-3">
          <Box className="font-bold text-[22px]">Pending Approval</Box>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography id="warning-modal-description" className="mt-2">
          Your sign-up request is pending approval from the administrator. Please contact your administrator for further assistance.
        </Typography>
        <div className="mt-4 flex justify-end">
          <button
            onClick={handleClose}
            className="mr-2 px-4 py-2 bg-gray-300 rounded"
          >
            Cancel
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default WarningModal;
