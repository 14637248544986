import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomTable from "../../common/Table";
import "./vehicle-parts-lookup-details.css";
import DealerPortalDrawer from "../../components/Layout/DealerPortalDrawer";
import { getModel } from "../../services/getModels";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { getModelsSystemSummary } from "../../services/getModelsSystemSummary";
import { CircularProgress } from "@mui/material";

const VehiclePartsLookupDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { instance, accounts } = useMsal();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const fetchModelsData = async (page) => {
    setIsLoading(true);
    try {
      const header = {
        authorization: `Bearer ${localStorage.getItem("vizy-bility-token")}`,
      };
      const url = `Parts/GetEquipmentSystemSummaryByModelId?modelId=${id}`;
      const data = await getModelsSystemSummary(url, null, header);

      const paginationHeader = data.headers.get("x-pagination");
      const pagination = paginationHeader ? JSON.parse(paginationHeader) : null;

      setData(data?.data || []);
      setTotalPages(pagination?.TotalPages || 1);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchModelsData(currentPage);
  }, [currentPage]);

  const columns = [
    {
      label: "Item",
      accessor: "Item",
      textAlignColumn: "center",
      render: (_, rowIndex) => <div>{rowIndex + 1 || "-"}</div>,
    },
    {
      label: "System Name",
      accessor: "SystemName",
      textAlignColumn: "start",
    },
    {
      label: "Action",
      accessor: "Action",
      textAlignColumn: "center",
      render: (_, rowIndex) => (
        <div
          onClick={() => {
            window.scrollTo(0, 0);

            navigate(
              `/dealers-portal/vehicle-parts-lookup/${location?.state?.modelData?.ModelId}/detail`,
              {
                state: {
                  modelData: location?.state?.modelData,
                  modelSystemData: data?.[rowIndex],
                }, // Replace with your actual state
              }
            );
          }}
          className="text-[#00B6FF] font-[600] text-[15px] cursor-pointer	underline underline-offset-4"
        >
          View
        </div>
      ),
    },
  ];
  return (
    <div className="pl-[21px]">
      <div className="flex  justify-between">
        <div className="text-[20px] sm:text-[26px] md:text-[36px] font-[600]">
          {location?.state?.modelData?.Model} Parts Lookup
        </div>
        <div className="block md:hidden">
          <DealerPortalDrawer />
        </div>
      </div>
      {isLoading ? (
        <div className="bg-white min-h-[85vh] flex flex items-center justify-center	">
          <CircularProgress />
        </div>
      ) : data?.length === 0 ? (
        <div className="min-h-[85vh] flex flex items-center justify-center text-[25px] font-bold">
          No Data Available
        </div>
      ) : (
        <div className="mx-auto py-4">
          <CustomTable headers={columns} data={data}/>
        </div>
      )}
    </div>
  );
};
export default VehiclePartsLookupDetails;
