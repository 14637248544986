import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import mainLogo from "../../../assets/png/tuataraLogo.png";
import MenuIcon from "../../../assets/svg/menuIcon.svg";
import Avtar from "../../../assets/images/Avtar.png";
import VehiclePartsIcon from "../../../assets/svg/Vehicle.svg";
import InventoryListIcon from "../../../assets/svg/InventoryList.svg";
import WarrantyIcon from "../../../assets/svg/Warranty.svg";
import SettingIcon from "../../../assets/svg/Setting.svg";
import DashboardIcon from "../../../assets/svg/dashboard.svg";
// import LogoutIcon from "../../../assets/svg/logout.svg";
import CloseIcon from "../../../assets/svg/closeIcon.svg";
import { Box, Drawer, Modal } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import OrderItemModel from "../../../common/OrderItemModel";
import LogoutIcon from "@mui/icons-material/Logout";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
  borderRadius: 2,
};

const MenuList = [
  {
    id: 1,
    name: "Dashboard",
    items: [
      {
        id: 1,
        name: "Vehicle Parts Lookup",
        icon: VehiclePartsIcon,
        link: "dealers-portal/vehicle-parts-lookup",
      },
      {
        id: 2,
        name: "Inventory List",
        icon: InventoryListIcon,
        link: "dealers-portal/inventory-list",
      },
      {
        id: 3,
        name: "Warranty",
        icon: WarrantyIcon,
        link: "dealers-portal/#",
      },
      {
        id: 4,
        name: "Setting",
        icon: SettingIcon,
        link: "dealers-portal/#",
      },
    ],
  },
];

const DealerPortalDrawer = () => {
  const location = useLocation();
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenMinScreenMenu, setIsOpenMinScreenMenu] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [outSideClick, setOutSideClick] = useState(false);
  const isLogin = JSON.parse(localStorage.getItem("client_info"));

  const Logout = async () => {
    try {
      localStorage.removeItem("vizy-bility-token");
      localStorage.removeItem("client_info");
      await instance.logoutRedirect({
        postLogoutRedirectUri: window.location.origin, // Redirect after logout
      });
      window.scrollTo(0, 0);
      navigate("/");
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  const toggleDrawer = () => setIsOpen(!isOpen);
  const toggleDrawerMinScreen = () =>
    setIsOpenMinScreenMenu(!isOpenMinScreenMenu);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const renderMenuItem = (navigationItem) => {
    const activePath = location.pathname.includes(navigationItem.link);
    return (
      <div
        key={navigationItem.id}
        className={`flex items-center cursor-pointer ${
          !isOpen ? "px-[35px]" : "justify-center"
        } my-[13px]`}
        onClick={() => {
          window.scrollTo(0, 0);
          navigate(navigationItem?.link);
        }}
      >
        <div className={`${!isOpen ? "mr-[18px]" : ""}`}>
          <img
            src={navigationItem.icon}
            width={"24px"}
            style={{ filter: activePath ? "" : "grayscale(100%)" }}
          />
        </div>
        {!isOpen && (
          <div
            className={`${
              activePath ? "text-[#7BBE5A]" : "text-[#9FA4A6]"
            } text-[14px] font-[600]`}
          >
            {navigationItem.name}
          </div>
        )}
      </div>
    );
  };

  const renderMenuMinScreenItem = (navigationItem) => {
    const activePath = location.pathname.includes(navigationItem.link);
    return (
      <div
        key={navigationItem.id}
        className={`flex items-start cursor-pointer px-[35px] my-[13px] justify-start`}
        onClick={() => {
          window.scrollTo(0, 0);
          navigate(navigationItem?.link);
        }}
      >
        <div className={`mr-[18px]`}>
          <img
            src={navigationItem.icon}
            width={"24px"}
            style={{ filter: activePath ? "" : "grayscale(100%)" }}
          />
        </div>
        <div
          className={`${
            activePath ? "text-[#7BBE5A]" : "text-[#9FA4A6]"
          } text-[14px] font-[600]`}
        >
          {navigationItem.name}
        </div>
      </div>
    );
  };

  return (
    <>
      {/* Show Big Screen */}
      <div
        className={`hidden sticky top-0 md:flex bg-[#FFFFFF] min-h-[100vh] border-[1px] border-[#0000002e]  flex-col h-full justify-between ${
          isOpen ? "w-[74px]" : "min-w-[310px]"
        }`}
      >
        <div>
          <div
            className={`flex ${
              !isOpen ? "px-[21px]" : "justify-center"
            } py-[13px]`}
          >
            {!isOpen && <img src={mainLogo} className="w-auto h-[80px]" />}
            <img
              src={MenuIcon}
              className={`w-[28px] cursor-pointer ${
                !isOpen ? "ml-[41px]" : ""
              }`}
              onClick={toggleDrawer}
            />
          </div>
          <div>
            {MenuList.map((menu) => (
              <React.Fragment key={menu.id}>
                <div
                  className={`border-y-[1px] border-[#00000030] ${
                    isOpen
                      ? "py-[21px] flex justify-center items-center text-center"
                      : "py-[21px] px-[35px] text-[24px] font-bold"
                  }`}
                >
                  {isOpen ? (
                    <img src={DashboardIcon} width={"24px"} />
                  ) : (
                    menu.name
                  )}
                </div>
                {menu.items.map(renderMenuItem)}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div>
          <div
            className={`flex items-center cursor-pointer border-y-[1px] border-[#00000030] py-[10px] ${
              !isOpen ? "px-[35px]" : "justify-center"
            }`}
            onClick={() => {
              window.location.replace("/");
            }}
          >
            <div className={`${!isOpen ? "mr-[18px]" : ""}`}>
              <HomeOutlinedIcon style={{ fontSize: "30px" }} />
            </div>
            <div className="text-[14px] font-[600] text-[#9FA4A6] cursor-pointer">
              Back to Home
            </div>
          </div>
          <div
            className={`flex items-center cursor-pointer border-y-[1px] border-[#00000030] py-[10px] ${
              !isOpen ? "px-[35px]" : "justify-center"
            }`}
            onClick={openModal}
          >
            <div className={`${!isOpen ? "mr-[18px]" : ""}`}>
              <LogoutIcon style={{ fontSize: "30px" }} />
            </div>
            {!isOpen && (
              <div className="text-[14px] font-[600] text-[#9FA4A6] cursor-pointer">
                LOGOUT
              </div>
            )}
          </div>
          <div
            className={`flex items-center py-[12px] px-[16px] ${
              isOpen
                ? "my-[10px]"
                : "mt-[20px] mb-[40px] mx-[35px] border-[0.25px] border-black"
            }`}
          >
            <div className={`${isOpen ? "" : "mr-[15px]"}`}>
              <img src={Avtar} width={"54px"} />
            </div>
            {!isOpen && (
              <div>
                <div className="font-[500] text-[18px]">
                  Hello, <strong>{isLogin?.given_name}</strong>
                </div>
                <div className="font-[500] text-[14px] text-[#9FA4A6]">
                  www.website.com
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Show Min Screen */}
      <div className="flex md:hidden">
        <div onClick={toggleDrawerMinScreen}>
          <img src={MenuIcon} className={`w-[28px] cursor-pointer`} />
        </div>

        <Drawer
          open={isOpenMinScreenMenu}
          anchor="right"
          onClose={toggleDrawerMinScreen}
        >
          <div className="bg-[#FFFFFF] min-h-[100vh] border-[1px] border-[#0000002e] flex flex-col h-full justify-between min-w-[310px]">
            <div>
              <div className="flex px-[21px] py-[13px]">
                <img src={mainLogo} className="w-auto h-[80px]" />
                <img
                  src={CloseIcon}
                  className="w-[28px] cursor-pointer ml-[41px]"
                  onClick={toggleDrawerMinScreen}
                />
              </div>
              <div>
                {MenuList.map((menu) => (
                  <React.Fragment key={menu.id}>
                    <div className="border-y-[1px] border-[#00000030] py-[21px] px-[35px] text-[24px] font-bold">
                      {menu.name}
                    </div>
                    {menu.items.map(renderMenuMinScreenItem)}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div>
              <div
                className={`flex items-center cursor-pointer border-y-[1px] border-[#00000030] py-[10px] ${
                  !isOpen ? "px-[35px]" : "justify-center"
                }`}
                onClick={() => {
                  setOutSideClick(true);
                }}
              >
                <OrderItemModel
                  refresh={isOpen}
                  outSideClick={outSideClick}
                  setOutSideClick={setOutSideClick}
                />
              </div>
              <div
                className={`flex items-center cursor-pointer border-y-[1px] border-[#00000030] py-[10px] ${
                  !isOpen ? "px-[35px]" : "justify-center"
                }`}
                onClick={() => {
                  window.location.replace("/");
                }}
              >
                <div className={`${!isOpen ? "mr-[18px]" : ""}`}>
                  <HomeOutlinedIcon style={{ fontSize: "24px" }} />
                </div>
                <div className="text-[14px] font-[600] text-[#9FA4A6] cursor-pointer">
                  Back to Home
                </div>
              </div>
              <div
                className="flex items-center cursor-pointer border-y-[1px] border-[#00000030] py-[10px] px-[35px]"
                onClick={openModal}
              >
                <div className="mr-[18px]">
                  {/* <img src={LogoutIcon} width={"24px"} /> */}

                  <LogoutIcon style={{ fontSize: "24px" }} />
                </div>
                <div className="text-[14px] font-[600] text-[#9FA4A6] cursor-pointer">
                  LOGOUT
                </div>
              </div>
              <div className="flex items-center py-[12px] px-[16px] mt-[20px] mb-[40px] mx-[35px] border-[0.25px] border-black">
                <div className="mr-[15px]">
                  <img src={Avtar} width={"54px"} />
                </div>
                <div>
                  <div className="font-[500] text-[18px]">
                    Hello, <strong>{isLogin?.given_name}</strong>
                  </div>
                  <div className="font-[500] text-[14px] text-[#9FA4A6]">
                    www.website.com
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </div>

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="p-6">
            <p className="mb-4 font-[600] text-[24px]">
              Are you sure you want to logout?
            </p>
            <div className="flex justify-center">
              <button
                onClick={closeModal}
                className="mr-2 px-4 py-2 bg-gray-300 rounded"
              >
                Cancel
              </button>
              <button
                onClick={Logout}
                className="px-4 py-2 bg-red-500 text-white rounded"
              >
                Logout
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default DealerPortalDrawer;
