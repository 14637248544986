import "./detail.css";
import React from "react";
import ProductComponents from "../../components/DetailComponents/productComponents";
import bulldozer5 from "../../assets/png/bulldozer5.png";
import location from "../../assets/png/location.png";
import top_speed from "../../assets/png/top_speed.png";
import fully_loaded from "../../assets/png/fully_Loaded.png";
import FeaturesComponents from "../../components/DetailComponents/featuresComponents";
import AddOns from "../../components/DetailComponents/addOns";
import ModularCab from "../../components/DetailComponents/modularCab";
import Slider from "react-slick";
import HeadingTitle from "../../common/headingTitle";
import {
  Detail1000EFIImg,
  Detail1000EFIPageCarouselData,
  Detail1000EFIPageData,
  Detail1000EFIProductData,
} from "../../common/string";
import ResponsiveTable from "./components/ResponsiveTable";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 963,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const Detail1000EFI = () => {
  return (
    <>
      {/* <div className="px-6 sm:px-10 md:px-12 lg:px-16 xl:px-20"> */}
      <div className="max-w-[1440px] mx-auto my-12 px-[3rem]">
        <div className="bg-[#221F1F] text-[#FFFFFF] text-xl py-4 rounded-full text-center">
          Check Out Our Finance Deals!
        </div>

        <div className="mt-20 mb-10">
          <ProductComponents ProductData={Detail1000EFIProductData} />
        </div>

        <div className="border-t-2 border-black pt-12">
          <div className="mb-[40px]">
            <HeadingTitle
              title={"FEATURES & DETAILS"}
              center={"!text-center"}
            />
          </div>
          <div>
            <FeaturesComponents is100EFI={true}/>
          </div>
        </div>

        <div className="mt-12">
          <AddOns />
        </div>

        <div className="pt-12">
          <div className="mb-12">
            <HeadingTitle title={"MODULAR CAB"} center={"!text-center"} />
          </div>
        </div>
        <div>
          <div>
            <ModularCab img={Detail1000EFIImg} />
          </div>
        </div>
      </div>
      {/* </div> */}

      <div className="mt-12 bg-[#2f2d2ec7] py-24">
        <div className="max-w-[1440px] mx-auto px-[3rem]">
          {/* <div className="px-6 sm:px-10 md:px-12 lg:px-16 xl:px-20"> */}
          <div className="relative pt-[56.25%] overflow-hidden max-w-full bg-black">
            <iframe
              className="absolute top-0 left-0 w-full h-full border-0"
              src="https://www.youtube.com/embed/bI5CctE_e7w?si=23QrLYCWxtVcCkK-"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
          {/* </div> */}
        </div>
      </div>

      <div className="max-w-[1440px] mx-auto px-[3rem]">
        <div className="my-12">
          <div>
            <HeadingTitle
              title={"POWER & PERFORMANCE"}
              center={"!text-center"}
            />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-10">
              <div className="col-span-1">
                <ResponsiveTable data={Detail1000EFIPageData?.PowerData} />
              </div>
              <div className="col-span-1">
                <ResponsiveTable
                  data={Detail1000EFIPageData?.PerformanceData}
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-10">
            <div className="col-span-1">
              <div className="mb-10">
                <HeadingTitle title={"CAPACITY"} center={"!text-center"} />
              </div>
              <ResponsiveTable data={Detail1000EFIPageData?.CapacityData} />
            </div>
            <div className="col-span-1">
              <div className="mb-10">
                <HeadingTitle title={"MATERIAL"} center={"!text-center"} />
              </div>
              <ResponsiveTable data={Detail1000EFIPageData?.MaterialData} />
            </div>
            <div className="col-span-1">
              <div className="mb-10">
                <HeadingTitle title={"DIMENSIONS"} center={"!text-center"} />
              </div>
              <ResponsiveTable data={Detail1000EFIPageData?.DimensionsData} />
            </div>
          </div>

          {/* <div className="slider-container mt-10">
            <Slider {...settings}>
              {Detail1000EFIPageCarouselData?.map((item, index) => (
                <div
                  key={index}
                  className="bg-white p-12 text-center mr-2 !flex flex-col !justify-between"
                >
                  <div>
                    <div className="font-bold text-xl text-[#78C15A]">
                      {item?.title}
                    </div>
                    <div className="font-medium text-lg text-black mt-2">
                      {item?.description}
                    </div>
                  </div>
                  <div className="mt-[85px]">
                    <button className="bg-[#78C15A] px-8 py-2 text-white">
                      VIEW DETAILS
                    </button>
                    <div className="font-semibold text-base text-black mt-[20px]">
                      OFFER ENDS {item?.date}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div> */}
        </div>
      </div>
    </>
  );
};
