import React from "react";
import tuataraLogo from "../../assets/png/tuataraLogo.png";
import yt from "../../assets/png/ytPng.png";
import lindIn from "../../assets/png/linkdinPng.png";
import fb from "../../assets/png/fbPng.png";
import inst from "../../assets/png/instaPng.png";

export default function FooterComponent() {
  const SocialLinks = [
    {
      id: 1,
      image: yt,
      link: "",
    },
    {
      id: 1,
      image: lindIn,
      link: "",
    },
    {
      id: 1,
      image: fb,
      link: "",
    },
    {
      id: 1,
      image: inst,
      link: "",
    },
  ];

  return (
    <footer className="bg-[#2F2D2E]">
      <div className="p-5 flex flex-col gap-3">
        <img src={tuataraLogo} className="mx-auto w-[205px] h-[82px]" />
        <p className="text-[16px] text-[#7BBE5A] text-center">
          15221 Market St, Channelview, TX 77530  || (432) 270-5411
          || Privacy Policy
        </p>

        <div className="flex flex-row mx-auto gap-4">
          {SocialLinks.map((link) => (
            <img src={link.image} key={link.id} className="w-[42px] h-[42px]" />
          ))}
        </div>
      </div>

      <div className="flex w-full pb-[2rem] pt-[3px] text-center">
        <p className="mx-auto p-2 text-[#000] bg-[#7BBE5A] w-full">
        © {new Date().getFullYear()} BY TAUTARA VEHICLES INC.
        </p>
      </div>
    </footer>
  );
}
