// JoditEditorComponent.js
import React, { useRef } from 'react';
import JoditEditor from 'jodit-react';

const JoditEditorComponent = ({ value, onChange }) => {
  const editor = useRef(null);

  // Configurations for Jodit editor
  const config = {
    readonly: false, // All options from https://xdsoft.net/jodit/docs/
    toolbarSticky: false,
    toolbarStickyOffset: 50,
    height: 400,
  };

  return (
    <div>
      <JoditEditor
        ref={editor}
        value={value}
        config={config}
        tabIndex={1} // Tab index for focusing
        onBlur={newContent => onChange(newContent)} // preferred to use only this option to update the content for performance reasons
        onChange={newContent => console.log(newContent)}
      />
    </div>
  );
};

export default JoditEditorComponent;
