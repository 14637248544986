import postApi from "../api/postApi";

export const postGetVizyBilityToken = ({ url, payload, headers }) => {
  return new Promise(async (resolve, reject) => {
    return postApi
      .post(url, payload, {
        headers: {
          ...headers, // Spread the headers provided by the caller
          "Content-Type":
            headers?.["Content-Type"] || "application/x-www-form-urlencoded", // Default to URL-encoded if not provided
        },
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
