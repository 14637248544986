import React, { useState, useEffect } from "react";
import UpArrowIcon from "../../assets/svg/up-arrow-icon.svg"

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled down
  const toggleVisibility = () => {
    if (window.scrollY > 20) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top when button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div>
      {isVisible && (
        <button
          type="button"
          onClick={scrollToTop}
          className="
          w-[50px]
          h-[50px]
          fixed 
          bottom-[40px] 
          right-4
          bg-[#79c15d]
          text-white 
          rounded-full 
          shadow-lg 
          hover:bg-[#83b370] 
          focus:outline-none 
          focus:ring-2 
          focus:ring-[#79c15d]
          focus:ring-opacity-50 
          transition-opacity 
          duration-300 
          transform 
          translate-y-4
          flex
          justify-center
          items-center
          "
          style={{
            opacity: isVisible ? 1 : 0,
            transition: "opacity 0.3s, transform 0.3s",
            transform: isVisible ? "translate-y-0" : "translate-y-4",
          }}
        >
          <img src={UpArrowIcon} alt="Up Icon" width={"20px"}/>
        </button>
      )}
    </div>
  );
};

export default ScrollToTopButton;
