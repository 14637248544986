import mainLogo from "../../../assets/png/tuataraLogo.png";
import Avtar from "../../../assets/images/Avtar.png";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Box, Modal } from "@mui/material";
import { useState } from "react";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
  outline:"none",
  borderRadius:2
};

const DealersHeader = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isLogin = JSON?.parse(localStorage.getItem("client_info"));
  const navigate = useNavigate();
  const { instance } = useMsal();

  const Logout = async () => {
    try {
      
      localStorage.removeItem("vizy-bility-token");
      localStorage.removeItem("client_info");
      await instance.logoutRedirect({
        postLogoutRedirectUri: window.location.origin, // Redirect after logout
      });
      navigate("/");
      closeModal()
    } catch (error) {
      console.error(error);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <header className="py-[22px]">
        <div className="max-w-[1440px] mx-auto px-[3rem] flex items-center justify-between px-[3rem]">
          <img
            src={mainLogo}
            className="w-auto h-[40px] xl:h-[70px] lg:h-[60px] md:h-[50px] sm:h-[40px]"
          />
          {/* <Navbar /> */}
          {/* <NavButtons /> */}

          <div className="flex items-center	border border-black rounded-[16px] py-[6px] px-[6px] md:py-[12px] md:px-[25px]">
            <div className="mr-[15px]">
              <div class="dropdown">
                <span>
                  <img src={Avtar} className="w-[40px] md:w-[58px]" />
                </span>
                <div class="dropdown-content">
                  <p
                    onClick={() => {
                      openModal();
                    }}
                    className="cursor-pointer"
                  >
                    {isLogin ? "LOGOUT" : "LOGIN"}
                  
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="font-[500] text-[16px] md:text-[20px]">
                Hello, <strong>{isLogin?.given_name}</strong>
              </div>
              <div className="font-[500] text-[14px] md:text-[16px] text-[#9FA4A6]">
                www.website.com
              </div>
            </div>
          </div>
        </div>
      </header>

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="p-6">
            <p className="mb-4 font-[600] text-[24px]">
              Are you sure you want to logout?
            </p>
            <div className="flex justify-center">
              <button
                onClick={closeModal}
                className="mr-2 px-4 py-2 bg-gray-300 rounded"
              >
                Cancel
              </button>
              <button
                onClick={Logout}
                className="px-4 py-2 bg-red-500 text-white rounded"
              >
                Logout
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default DealersHeader;
