import React, { useState } from 'react';
import { Modal, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ModularCab = ({ img }) => {
  const images = img;
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleOpen = (index) => {
    setCurrentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {images?.map((item, index) => (
          <div className="col-span-1 flex justify-center" key={index}>
            <img src={item} alt="nature" className="w-full cursor-pointer	"  onClick={() => handleOpen(index)}/>
          </div>
        ))}
      </div>
      <Modal open={open} onClose={handleClose}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          bgcolor="rgba(0,0,0,0.9)"
          position="relative"
        >
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 10, right: 10, color: "white" }}
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            onClick={handlePrev}
            sx={{ position: "absolute", left: 10, color: "white" }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <img
            src={images[currentIndex]}
            alt={`full-img-${currentIndex}`}
            style={{ maxHeight: "90%", maxWidth: "90%" }}
          />
          <IconButton
            onClick={handleNext}
            sx={{ position: "absolute", right: 10, color: "white" }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
      </Modal>
    </>
  );
};

export default ModularCab;
