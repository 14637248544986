import React from "react";

const ResponsiveTable = ({ data }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full border-collapse border-spacing-2 table">
        <tbody>
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <tr className="border border-black">
                <td className="py-4 px-5 text-xl text-green-600 font-bold border-r border-black">
                  {item.key}
                </td>
                <td className="py-4 px-5 text-lg border-black">{item.value}</td>
              </tr>
              <tr className="spacer">
                <td colSpan="2">
                  <div className="h-2" />
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default ResponsiveTable;
