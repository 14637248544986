import React, { useEffect, useState } from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { toast } from "react-toastify";
import { Button } from "@mui/material";

const CustomTable = ({
  handleOutSideData,
  headers,
  data,
  maxWidth,
  select,
  selectOutSideData,
  refresh
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  // Handle individual row selection
  const handleCheckboxChange = (rowIndex, row) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(rowIndex)
        ? prevSelected.filter((index) => index !== rowIndex)
        : [...prevSelected, rowIndex]
    );
  };

  // Handle "Select All" checkbox
  const handleSelectAllChange = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(data.map((item, index) => item?.PartNumber));
    }
  };

  const handelAddCard = () => {
    const filteredData = data.filter((item) =>
      selectedRows.includes(item.PartNumber)
    );

    const defultCartItem = JSON.parse(localStorage.getItem("add_cart")) || []
    localStorage.setItem("add_cart", JSON.stringify([...filteredData,...defultCartItem]));
    setSelectedRows([]);
    toast.success("Successfully added to cart.");
    refresh()
  };

  return (
    <div className="bg-[#FFFFFF] overflow-x-auto">
      {select && selectedRows?.length > 0 && (
        <div className="flex justify-end	mt-2 mr-4">
          <button
            className="bg-[#78C15A] p-3 text-white rou"
            onClick={() => handelAddCard()}
          >
            <ShoppingCartIcon /> Add to Cart
          </button>
        </div>
      )}
      <div
        className={`overflow-y-auto max-h-[${maxWidth ? maxWidth : "100%"}]`}
      >
        <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
          <thead className="sticky top-0 bg-white">
            <tr>
              {select && (
                <th className="text-[16px] font-[600] py-[23px] px-[30px] text-left">
                  <input
                    type="checkbox"
                    checked={
                      selectedRows.length === data.length && data.length > 0
                    }
                    onChange={handleSelectAllChange}
                  />
                </th>
              )}
              {headers.map((header, index) => (
                <th
                  key={index}
                  className={`text-[16px] font-[600] py-[23px] px-[10px] text-${header?.textAlignColumn}`}
                  style={{ textAlign: header?.textAlignColumn }}
                >
                  {header?.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className={`${
                  select && selectedRows.includes(row?.PartNumber)
                    ? "bg-gray-200"
                    : ""
                } ${
                  selectOutSideData?.EquipmentSystemId &&
                  selectOutSideData?.EquipmentSystemId ===
                    row?.EquipmentSystemId
                    ? "bg-gray-200"
                    : ""
                }`}
              >
                {select && (
                  <td className="text-[#9FA4A6] font-[600] py-[15px] px-[30px] text-left">
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(row?.PartNumber)}
                      onChange={() =>
                        handleCheckboxChange(row?.PartNumber, row)
                      }
                    />
                  </td>
                )}
                {headers.map((header, colIndex) => (
                  <td
                    key={colIndex}
                    className={`text-[#9FA4A6] font-[600] py-[15px] px-[10px] text-${
                      header?.textAlignColumn
                    } ${handleOutSideData ? "cursor-pointer" : ""}`}
                    onClick={() =>
                      handleOutSideData && !header?.render
                        ? handleOutSideData(row)
                        : null
                    }
                  >
                    {header?.render
                      ? header?.render(row[header?.accessor], rowIndex)
                      : row[header?.accessor] || "-"}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomTable;
