import postApi from "../api/postApi";
import { URL } from "../api/url";

// Get Model Details
export const getModel = (url, payload, header) => {
  return new Promise(async (resolve, reject) => {
    return postApi
      .post(URL + "/" + url, payload, header)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
