import { useNavigate } from "react-router-dom";
import CustomTable from "../../common/Table";
import "./inventory-list.css";
import DealerPortalDrawer from "../../components/Layout/DealerPortalDrawer";
import { getModel } from "../../services/getModels";
import { useEffect, useState } from "react";
import Pagination from "../../common/Pagination";
import { CircularProgress } from "@mui/material";
import { useMsal } from "@azure/msal-react";

const InventoryList = () => {
  const isLogin = JSON.parse(localStorage.getItem("client_info"));
  const { instance, accounts } = useMsal();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const columns = [
    {
      label: "Unit Number",
      accessor: "UnitNumber",
      textAlignColumn: "center",
      render: (_, rowIndex) => <div>{data[rowIndex]?.UnitNumber || "-"}</div>,
    },
    {
      label: "Asset Description",
      accessor: "AssetDescription",
      textAlignColumn: "center",
      render: (_, rowIndex) => <div>{data[rowIndex]?.AssetDescription || "-"}</div>,
    },
    {
      label: "Serial Number",
      accessor: "SerialNumber",
      textAlignColumn: "center",
    },
    {
      label: "Make",
      accessor: "MakeName",
      textAlignColumn: "center",
    },
    {
      label: "Model",
      accessor: "Model",
      textAlignColumn: "center",
    },
    {
      label: "Model",
      accessor: "Model",
      textAlignColumn: "center",
    },
    {
      label: "Asset Type",
      accessor: "AssetTypeName",
      textAlignColumn: "center",
    },
    {
      label: "Asset Status",
      accessor: "AssetStatusName",
      textAlignColumn: "center",
    },
    {
      label: "Location Name",
      accessor: "LocationName",
      textAlignColumn: "center",
    }
  ];

  const fetchModelsData = async (page) => {
    setIsLoading(true);
    try {
      const header = {
        authorization: `Bearer ${localStorage.getItem("vizy-bility-token")}`,
      };
      const payload = {
        includeCount: true,
        pageNumber: page,
        filter: {
          companyIds: [Number(isLogin?.extension_CompanyId)],
        },
        pageSize: 10,
      };

      const url = "Asset/GetPagedAssets";
      const data = await getModel(url, payload, header);
      const pagination = JSON.parse(data.headers.get("x-pagination"));
      setData(data?.data);
      setTotalPages(pagination?.TotalPages);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchModelsData(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="pl-[21px]">
      <div className="flex  justify-between">
        <div className="text-[20px] sm:text-[26px] md:text-[36px] font-[600]">
          Inventory List
        </div>
        <div className="block md:hidden">
          <DealerPortalDrawer />
        </div>
      </div>
      <div className="mx-auto py-4">
        {isLoading ? (
          <div className="bg-white min-h-[85vh] flex flex items-center justify-center	">
            <CircularProgress />
          </div>
        ) : data?.length === 0 ? (
          <div className="min-h-[85vh] flex flex items-center justify-center text-[25px] font-bold">
            No Data Available
          </div>
        ) : (
          <div className="bg-white min-h-[85vh] flex flex-col justify-between">
            <CustomTable headers={columns} data={data} />
            <div className="p-4 flex justify-end">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default InventoryList;
