import getApi from "../api/getApi";
import { URL } from "../api/url";

// Get Model Details
export const getModelsSystemSummary = (url) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem("vizy-bility-token");
      if (!token) {
        throw new Error("Authorization token is missing");
      }

      const headers = {
        authorization: `Bearer ${token}`,
      };

      const response = await getApi.get(`${URL}/${url}`, {
        headers,
      });

      if (response) {
        resolve(response);
      } else {
        throw new Error("No response received from API");
      }
    } catch (err) {
      console.error("Error fetching model system summary:", err);
      reject(err.response || err);
    }
  });
};
