import React, { useState, useEffect } from "react";
import Navbar from "./navbar";
import "./index.css";
import mainLogo from "../../assets/png/tuataraLogo.png";
import NavButtons from "./navButtons";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/msalConfig";
import { jwtDecode } from "jwt-decode";
import { ApplicationId, vizybilityConfig } from "../../api/url";
import { postGetVizyBilityToken } from "../../services/getVizyBilityToken";

export const Header = () => {
  const { instance, accounts } = useMsal();
  const [openWarning, setOpenWarning] = useState(
    localStorage.getItem("OpenWarning") || false
  );
  const [isScrolled, setIsScrolled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleOpenWarning = () => {
    setOpenWarning(true);
    localStorage.setItem("OpenWarning", true);
  };

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      console.error("Login error:", error);
      setIsLoading(false);
    }
  };

  const handleRedirectCallback = async () => {
    try {
      const response = await instance.handleRedirectPromise();
      if (response && response.idToken) {
        const decodedToken = jwtDecode(response.idToken);
        // Check user status and handle accordingly
        if (decodedToken?.extension_UserStatus) {
          setIsLoading(false); // Stop loading after processing response
          if (
            decodedToken.extension_UserStatus === undefined ||
            decodedToken.extension_UserStatus === "Pending"
          ) {
            handleOpenWarning();
            await instance.logoutRedirect({
              postLogoutRedirectUri: window.location.origin,
            });
            localStorage.removeItem("vizy-bility-token");
            localStorage.removeItem("client_info");
          } else if (decodedToken.extension_UserStatus === "Approved") {
            let payload = new URLSearchParams({
              grant_type: vizybilityConfig.grant_type,
              username: decodedToken.extension_GenericWebUsername,
              password: decodedToken.extension_GenericWebUserPassword,
              client_id: vizybilityConfig.STS_CLIENT_ID,
              client_secret: vizybilityConfig.STS_CLIENT_SECRET,
              scope: vizybilityConfig.STS_SCOPE,
              ApplicationId: ApplicationId,
            });

            postGetVizyBilityToken({
              url: vizybilityConfig.STS_SIGNIN_URL,
              payload,
            })
              .then((res) => {
                if (res?.data?.access_token) {
                  localStorage.setItem(
                    "vizy-bility-token",
                    res.data.access_token
                  );
                  localStorage.setItem(
                    "client_info",
                    JSON.stringify(decodedToken)
                  );
                  window.scrollTo(0, 0);
                  navigate("/dealers-portal");
                }
              })
              .catch(async (err) => {
                handleOpenWarning();
                await instance.logoutRedirect({
                  postLogoutRedirectUri: window.location.origin,
                });
                localStorage.removeItem("vizy-bility-token");
                localStorage.removeItem("client_info");
              });
          } else {
            handleOpenWarning();
            await instance.logoutRedirect({
              postLogoutRedirectUri: window.location.origin,
            });
            localStorage.removeItem("vizy-bility-token");
            localStorage.removeItem("client_info");
          }
        } else {
          handleOpenWarning();
          await instance.logoutRedirect({
            postLogoutRedirectUri: window.location.origin,
          });
          localStorage.removeItem("vizy-bility-token");
          localStorage.removeItem("client_info");
        }
      }
    } catch (error) {
      console.error("Error handling redirect callback:", error);
      setIsLoading(false); // Stop loading on error
    } finally {
      setIsLoading(false);
    }
  };

  const initializeApp = async () => {
    setIsLoading(true);
    await handleRedirectCallback();
  };

  useEffect(() => {
    setTimeout(() => {
      initializeApp();
    }, 100);
  }, []);

  return (
    <header
      className={`sticky top-0 z-10 header-bg transition-colors duration-300`}
    >
      {isLoading ? (
        <div className="absolute w-[100%] h-[100dvh] bg-[#00000066] flex justify-center items-center">
          <span class="loader"></span>
        </div>
      ) : null}
      <div className="max-w-[1440px] mx-auto flex items-center justify-between px-[3rem]">
        <img
          src={mainLogo}
          className="w-auto h-[40px] xl:h-[70px] lg:h-[60px] md:h-[50px] sm:h-[40px] cursor-pointer"
          onClick={() => {
            window.scrollTo(0, 0);
            navigate("/");
          }}
        />
        <Navbar
          openWarning={openWarning}
          setOpenWarning={setOpenWarning}
          handleLogin={handleLogin}
        />
        <NavButtons
          openWarning={openWarning}
          setOpenWarning={setOpenWarning}
          handleLogin={handleLogin}
        />
      </div>
    </header>
  );
};
