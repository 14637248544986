export const msalConfig = {
  auth: {
    clientId: "8aab1003-3c1b-4cfc-ae68-879d59e3b052", // This is the ONLY mandatory field that you need to supply.
    authority:
      "https://tutaraalphaomega.b2clogin.com/tutaraalphaomega.onmicrosoft.com/B2C_1_SIGNINSIGNUP",

    // Choose SUSI as your default authority.
    knownAuthorities: [
      "tutaraalphaomega.b2clogin.com", // Trusted B2C domain
    ], // Mark your B2C tenant's domain as trusted.
    redirectUri: window.location.origin, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: window.location.origin, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If 'true', will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
  },
};

// export const loginRequest = {
//   scopes: ["openid", "profile", "defult"],
//   //scopes: ["openid", "profile", "User.Read"],
// };

export const loginRequest = {
  scopes: [
    "openid",
    "profile",
    "offline_access",
    "8aab1003-3c1b-4cfc-ae68-879d59e3b052",
  ],
};
